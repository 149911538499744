import React from "react";
import { CONTACT } from "../../constants/index";
import logo from "../../assets/hpc logo.jpg";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa6";
import bg from "../../assets/bg/FooterBg.svg";
const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else navigate("/");
  };
  return (
    <footer
      className="lg:px-8  text-[17px] bg-opacity-30 font-semibold text-secondary px-8 py-8"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}>
      <div className="lg:justify-start flex items-center justify-center gap-2 mb-4">
        <img
          src={logo}
          alt="Logo"
          className=" w-[60px] cursor-pointer"
          onClick={handleOnClick}
        />
        <h3
          className=" text-nowrap text-[#001991] lg:text-2xl font-serif cursor-pointer"
          onClick={handleOnClick}>
          HPC Medical Staffing LLC
        </h3>
      </div>
      <div className="lg:flex-row lg:text-start lg:gap-12 container flex flex-col justify-between mx-auto text-center">
        <div className="lg:flex-1 flex flex-col gap-2">
          <a
            href={`tel:${CONTACT.phoneNumber1}`}
            className="hover:text-hover items-center block gap-2">
            Phone: {CONTACT.phoneNumber1}
          </a>{" "}
          <a
            href={`tel:${CONTACT.phoneNumber2}`}
            className="hover:text-hover lg:ml-[58px] items-center block gap-2">
            {CONTACT.phoneNumber2}
          </a>
          <a
            href={`mailto:${CONTACT.email}`}
            className="hover:text-hover items-center block gap-2">
            E-mail: {CONTACT.email}
          </a>
          <a
            href="https://www.google.com/maps?q=58+Hunters+Village+Way,+Manchester,+NH+03103,+USA"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-hover ">
            <p className="">Address: 58 Hunters Village Way,</p>
            <p className="lg:ml-[74px]"> Manchester, NH, 03103, USA</p>
          </a>
          <p className=" py-2">We're Open: Mon to Sat, 9:00AM - 6:00PM</p>
          <div className="lg:justify-start flex justify-center gap-3 py-2">
            <a
              href={CONTACT.instagram}
              target="_blank"
              className="hover:scale-125 p-2 transition-all duration-200 bg-gray-500 rounded-full cursor-pointer">
              <FaInstagram className="text-white" />
            </a>
            <a
              href={CONTACT.facebook}
              target="_blank"
              className="hover:scale-125 p-2 transition-all duration-200 bg-gray-500 rounded-full cursor-pointer">
              <FaFacebookF className="text-white" />
            </a>
            {/* <a
              href={CONTACT.linkedin}
              className="hover:scale-125 p-2 transition-all duration-200 bg-gray-500 rounded-full cursor-pointer">
              <FaLinkedin className="text-white" />
            </a> */}
          </div>
        </div>
        <ul className="lg:flex lg:text-start lg:gap-4 gap-y-8 lg:space-y-0 flex-col flex-1 flex-grow space-y-3 text-center">
          <li className="w-full" onClick={handleOnClick}>
            <Link
              to="/"
              className="hover:text-hover text-nowrap lg:border-b lg:border-gray-300 lg:mt-0 flex-grow block mt-5">
              Home
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="/about-us"
              className="hover:text-hover text-nowrap lg:border-b lg:border-gray-300 flex-grow block">
              About Us
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="/services"
              className="hover:text-hover text-nowrap lg:border-b lg:border-gray-300 flex-grow block">
              Services
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="/career"
              className="hover:text-hover text-nowrap lg:border-b lg:border-gray-300 flex-grow block">
              Career
            </Link>
          </li>
        </ul>

        <ul className="lg:flex lg:space-y-0 lg:pt-0 lg:text-start flex-col items-start flex-1 flex-grow w-full gap-4 pt-3 space-y-3 text-center">
          <li className="w-full">
            <Link
              to="/hire-us"
              className="hover:text-hover text-nowrap lg:border-b lg:border-gray-300 flex-grow block">
              Hire Us
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="/login"
              className="hover:text-hover text-nowrap lg:border-b lg:border-gray-300 flex-grow block">
              Staff
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="/terms-of-service"
              className="hover:text-hover text-nowrap lg:border-b lg:border-gray-300 flex-grow block">
              Terms of Service
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="/privacy-policy"
              className="hover:text-hover text-nowrap lg:border-b lg:border-gray-300 flex-grow block">
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
      <p className="pt-5 mt-4 text-center border-t border-gray-400">
        © {new Date().getFullYear()} HPC Medical Staffing LLC. All rights
        reserved.
      </p>
    </footer>
  );
};

export default Footer;
