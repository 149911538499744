import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { ITEMS_PER_PAGE } from "../../constants";
const Table = ({ data, onClick }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = data?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <table className="min-w-full bg-white rounded-lg shadow-md">
        <thead className=" rounded-3xl">
          <tr className=" lg:grid-cols-4 lg:gap-5 grid grid-cols-3 gap-1 px-2 mb-2">
            <th className="rounded-2xl bg-formTitle lg:px-4 lg:py-3 flex items-center justify-center px-1 py-1 text-center text-white">
              S.No
            </th>
            <th className="rounded-2xl bg-formTitle lg:px-4 flex items-center justify-center px-1 py-3 text-center text-white">
              Name
            </th>
            <th className="rounded-2xl bg-formTitle lg:px-4 flex items-center justify-center px-1 py-3 text-center text-white">
              Date of Submission
            </th>
            <th className=" rounded-2xl bg-formTitle lg:block hidden px-4 py-3 text-center text-white">
              Time of Submission
            </th>
          </tr>
        </thead>
        <tbody className="p-4 text-gray-800">
          {paginatedData?.map((item, index) => (
            <tr
              key={`${item.id}_${index}`}
              className="hover:cursor-pointer group gap-y-4 lg:grid-cols-4 grid grid-cols-3 p-2"
              onClick={() => {
                onClick(item.id);
              }}>
              <td className="rounded-l-2xl lg:flex group-hover:bg-gray-400 lg:px-4 items-center justify-center px-1 py-3 text-center bg-gray-200">
                {index + 1}
              </td>
              <td className="group-hover:bg-gray-400 lg:flex lg:px-4 items-center justify-center px-1 py-3 text-center bg-gray-200">
                {`${item.firstName.substring(0, 10)}${
                  item.firstName.length > 10 ? "..." : ""
                } ${
                  item.middleName
                    ? item.middleName.substring(0, 10) +
                      (item.middleName.length > 10 ? "..." : "")
                    : ""
                } ${
                  item.lastName.substring(0, 10) +
                  (item.lastName.length > 10 ? "..." : "")
                }`}
              </td>

              <td className="group-hover:bg-gray-400 lg:flex rounded-r-2xl lg:rounded-none lg:px-4 items-center justify-center px-1 py-3 text-center bg-gray-200">
                {item.date}
              </td>
              <td className="rounded-r-2xl lg:flex group-hover:bg-gray-400 items-center justify-center hidden px-4 py-3 text-center bg-gray-200">
                {item.time}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {paginatedData?.length === 0 && (
        <p className=" text-xl text-center">No data available</p>
      )}
      {paginatedData?.length !== 0 && (
        <div className="flex items-center justify-center space-x-2">
          <div className="flex justify-start my-4">
            {currentPage !== 1 && (
              <div
                className="text-accent hover:text-hover inline-flex items-center space-x-1 text-sm font-medium cursor-pointer"
                onClick={() => handlePageChange(currentPage - 1)}>
                <FaArrowLeft className="size-4" />
                <span>Previous</span>
              </div>
            )}
          </div>

          <span>
            Page {currentPage} of {totalPages}
          </span>

          {currentPage !== totalPages && (
            <div className="flex justify-start my-4">
              <div
                className="text-accent hover:text-hover inline-flex items-center space-x-1 text-sm font-medium cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}>
                <span>Next</span>
                <FaArrowRight className="size-4" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Table;
