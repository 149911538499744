import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import toast, { Toaster } from "react-hot-toast";
import { auth } from "../firebase";
import { FaArrowLeft } from "react-icons/fa6";

import bg from "../assets/bg/service.svg";

import logo from "../assets/hpc logo.jpg";
import "../components/styles/index.css";
const Admin = () => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const emailBeforeAt = user.email.split("@")[0];
        setEmail(emailBeforeAt);
      }
    });
    return unsubscribe;
  }, []);

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User signed out!");
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("Error signing out");
    }
  };
  return (
    <>
      <div
        className="h-screen"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}>
        <div className=" pr-[52px] flex justify-center px-4 py-8">
          <Link
            to="/"
            className="text-accent hover:text-hover lg:items-center inline-flex mb-4 mr-auto space-x-1 text-sm font-medium">
            <FaArrowLeft className="size-4" />
            <span className="font-medium">Back</span>
          </Link>
          <NavLink
            to="/"
            className=" flex flex-col items-center mr-auto space-y-2">
            <img src={logo} alt="" className="size-12" />
            <p className=" font-serif font-semibold">HPC Medical Staffing</p>
          </NavLink>
        </div>
        <div className="lg:px-8 lg:py-0 flex flex-col px-4 pb-8">
          <Toaster />
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className=" text-2xl font-extrabold text-center text-gray-900">
              Welcome, <span className="text-secondary">{email}</span>
            </h2>

            <div className="sm:mx-auto sm:w-full sm:max-w-md mt-">
              <div className="sm:rounded-lg sm:px-10 px-2 py-8 bg-white shadow">
                <p className="text-secondary mb-6 text-center">
                  Select the list that you wish to view
                </p>
                <div className=" space-y-4 align-middle">
                  <Link
                    to="/employer-list"
                    className="myButton b flex justify-center w-[75%]  px-2 py-4 text-sm font-medium hover:text-white hover:scale-90 transition-all duration-300 border border-secondary rounded-3xl mx-auto shadow-sm">
                    Employer List
                  </Link>
                  <Link
                    to="/employment-list"
                    className="myButton flex justify-center w-[75%]  px-2 py-4 text-sm font-medium hover:text-white hover:scale-90 transition-all duration-300 border border-secondary rounded-3xl mx-auto shadow-sm">
                    Employment List
                  </Link>
                  <Link
                    to="/contact-list"
                    className="myButton flex justify-center w-[75%]  px-2 py-4 text-sm font-medium hover:text-white hover:scale-90 transition-all duration-300 border border-secondary rounded-3xl mx-auto shadow-sm">
                    Contact List
                  </Link>
                  <div className="border-t-secondary border"></div>
                  <button
                    onClick={handleLogout}
                    className=" hover:scale-90 transition-all duration-300 flex justify-center w-[50%]  px-4 py-4 text-sm font-medium hover:text-white  hover:bg-[#FF4646]  lg:text-[18px]  rounded-3xl mx-auto shadow-md">
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
