// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCIkO_5rWdacnguXBtd9X6sU8jN2EmrGiQ",
  authDomain: "hpc-medical-staffing-98b38.firebaseapp.com",
  databaseURL: "https://hpc-medical-staffing-98b38-default-rtdb.firebaseio.com",
  projectId: "hpc-medical-staffing-98b38",
  storageBucket: "hpc-medical-staffing-98b38.appspot.com",
  messagingSenderId: "29051351456",
  appId: "1:29051351456:web:b14a11c4d9cb4c41d6b4c5",
  measurementId: "G-QZW2BN3N3L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);

export { auth, app, db, storage, analytics, firestore };
