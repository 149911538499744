export const EMPLOYMENT_FIELDS = {
  //Contact
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  phoneNumber: "Telephone number",
  email: "Email Id",
  address: "Street",
  city: "City",
  state: "State",
  pinCode: "Zip Code",
  workEntitle: "Are you legally entitled to work in U.S.?",
  //Position
  registered_nurse: "RN",
  lp_nurse: "LP",
  lna_cna: "LNA/CNA",

  //Education
  schoolName: "School name",
  degree: "Degree",
  specialSkills1: "Specials Skills 1",
  specialSkills2: "Specials Skills 2",
  specialSkills3: "Specials Skills 3",
  //Work Experience
  employer: "Employer",
  employer_number: "Telephone Number",
  employer_address: "Address",
  working_from: "Working from",
  worked_until: "To",
  jobTitle: "Job Title",
  working_hoursPerWeek: "Hours per week",
  specificDuties: "Specific Duties",
  lastSalary: "Last salary ($)",
  reasonForLeaving: "Reason for Leaving",
  contactEmployer: "May we contact this employer?",
  reference: "List individuals who are familiar with your work ",
  referenceName: "1) Name",
  referenceRelationship: "Relationship",
  referenceNumber: "Telephone Number",
  referenceAddress: "Address",
  referenceName2: "2) Name",

  referenceRelationship2: "Relationship",
  referenceNumber2: "Telephone Number",
  referenceAddress2: "Address",

  referenceName3: "3) Name",
  referenceRelationship3: "Relationship",
  referenceNumber3: "Telephone Number",
  referenceAddress3: "Address",
  authorization:
    " I here by authorize the above individuals/agencies to release information related to my employment to HPC LLC. (HPC Medical Staffing",
  date: "Date",
  signature: "Signature of applicant",
  carAvailable:
    "I have a car available and I will utilize it in the performance of my duties",
  validLicense: "I have a current, VALID driver's License",
  insuranceRequirement:
    "I have automobile insurance on my vehicle, or I am willing to place Insurance which meets HPC Requirements (Liability Insurance $100,00/$300,00 per person, per accident)",
  ageVerification:
    "I can provide documentation that I am at least eighteen (18) years of age.",
  overTime: "I am willing to work overtime, if required by my position",
  jobDescriptionUnderstanding:
    "Based on a review of the description of the job of which you have applied, are you able to meet and/or perform the qualifications, requirements, and job specifications as defined in the job description",
  criminalRecord:
    "Have you ever pleaded guilty, or been found guilty of a crime,civilian or ,military? If yes please provide details.",
};
