import React, { useEffect } from "react";
import rn from "../assets/services/RN.jpg";
import lpn from "../assets/services/LPN.jpg";
import lnaCna from "../assets/services/LNA-CNA.jpg";
import bg from "../assets/bg/service.svg";
import bg1 from "../assets/bg/serviceReverse.svg";
import { Link } from "react-router-dom";
import Button from "../components/UI/Button";
import ScrollToTop from "../components/ScrollToTop";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();
logEvent(analytics, "Service page");

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ScrollToTop />
      <section className="lg:py-8 ">
        <h2 className="lg:text-4xl lg:px-32 px-4 mb-6 font-serif text-3xl font-medium text-center text-gray-800">
          Our Services
        </h2>
        <p className=" lg:px-32 px-8 mb-6 text-lg text-center text-gray-700">
          Today, HPC Medical Staffing continues to grow, adapt, and improve its
          services to meet the changing needs of the healthcare industry. With a
          team of experienced recruiters, a vast database of qualified medical
          professionals, and a commitment to excellence, HPC Medical Staffing
          has become a trusted partner to healthcare facilities across New
          Hampshire.
        </p>
        <div
          className="md:grid-cols-2 lg:grid-cols-2 lg:px-32 grid grid-cols-1 gap-8 px-8 pt-4 pb-8"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          {/* Expertise */}
          <div className="overflow-hidden bg-white rounded-lg shadow-lg">
            <div className="p-6">
              <h3 className="mb-4 text-xl font-bold">Expertise</h3>
              <p className=" text-justify text-gray-700">
                With years of experience in the medical staffing industry, we
                have developed a deep understanding of the unique challenges and
                demands faced by healthcare facilities. Our team of experts is
                dedicated to providing customized staffing solutions that meet
                your needs.
              </p>
            </div>
          </div>

          {/* Quality */}
          <div className="overflow-hidden bg-white rounded-lg shadow-lg">
            <div className="p-6">
              <h3 className="mb-4 text-xl font-bold">Quality</h3>
              <p className="text-justify text-gray-700">
                From rigorous screening and credentialing processes to ongoing
                training and support, we ensure that every healthcare
                professional meets the highest standards.
              </p>
            </div>
          </div>

          {/* Flexibility */}
          <div className="overflow-hidden bg-white rounded-lg shadow-lg">
            <div className="p-6">
              <h3 className="mb-4 text-xl font-bold">Flexibility</h3>
              <p className="text-justify text-gray-700">
                We recognize that every healthcare facility has its own staffing
                needs and challenges. That's why we offer flexible staffing
                solutions that can be tailored to your specific requirements.
                Whether you need temporary staffing to cover a short-term
                shortage or permanent placement for long-term staffing needs, we
                have you covered.
              </p>
            </div>
          </div>

          {/* 24/7 Support */}
          <div className="overflow-hidden bg-white rounded-lg shadow-lg">
            <div className="p-6">
              <h3 className="mb-4 text-xl font-bold">24/7 Support</h3>
              <p className="text-justify text-gray-700">
                Our commitment to customer service extends beyond regular
                business hours. We provide round-the-clock support to address
                any staffing emergencies or concerns, ensuring continuity of
                care for your patients.
              </p>
            </div>
          </div>
        </div>

        <div
          className="lg:px-32 px-8"
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          <div className="md:grid-cols-2 lg:grid-cols-3 grid grid-cols-1 gap-8">
            {/* RN (Registered Nurse) */}
            <div className="relative flex flex-col overflow-hidden bg-white rounded-lg shadow-lg">
              <img src={rn} alt="RN" className="object-cover w-full" />
              <div className="flex-grow p-6">
                <h3 className="lg:text-start mb-4 text-xl font-bold text-center">
                  RN (Registered Nurse)
                </h3>
                <p className="text-justify text-gray-700">
                  We recruit top-tier registered nurses tailored to your needs,
                  ensuring exceptional patient care and seamless staffing
                  solutions. Our RNs are equipped to work in various settings,
                  including nursing homes, assisted living facilities,
                  rehabilitation centers, and wellness centers.
                </p>
              </div>
              <div className="flex my-4">
                <Link to="/hire-us" className="mx-auto">
                  <Button> Hire Us</Button>
                </Link>
              </div>
            </div>

            {/* LPN (Licensed Practical Nurse) */}
            <div className="relative flex flex-col overflow-hidden bg-white rounded-lg shadow-lg">
              <img src={lpn} alt="LPN" className="object-cover w-full" />
              <div className="flex-grow p-6">
                <h3 className="lg:text-start mb-4 text-xl font-bold text-center">
                  LPN (Licensed Practical Nurse)
                </h3>
                <p className="text-justify text-gray-700">
                  Dedicated LPNs ready to support your facility with flexible
                  staffing options, providing quality care and reliable
                  assistance. Our LPNs are available for hire at nursing homes,
                  assisted living facilities, rehabilitation centers, and
                  wellness centers.
                </p>
              </div>
              <div className="flex my-4">
                <Link to="/hire-us" className="mx-auto">
                  <Button> Hire Us</Button>
                </Link>
              </div>
            </div>

            {/* LNA/CNA (Licensed Nursing Assistant/Certified Nursing Assistant) */}
            <div className=" relative flex flex-col overflow-hidden bg-white rounded-lg shadow-lg">
              <img src={lnaCna} alt="LNA/CNA" className="object-cover w-full" />
              <div className="flex-grow p-6">
                <h3 className="lg:text-start mb-4 text-xl font-bold text-center">
                  LNA/CNA (Licensed Nursing Assistant/Certified Nursing
                  Assistant)
                </h3>
                <p className="text-justify text-gray-700">
                  Skilled LNAs/CNAs promptly placed to enhance your team,
                  delivering invaluable support and maintaining high standards
                  of patient care. Hire our LNAs/CNAs for work at nursing homes,
                  assisted living facilities, rehabilitation centers, and
                  wellness centers.
                </p>
              </div>
              <div className="flex my-4">
                <Link to="/hire-us" className="mx-auto">
                  <Button> Hire Us</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
