import { doc, setDoc } from "firebase/firestore";
import { firestore } from ".";

export const errorLog = async (collectionName, data, error, path) => {
  const currentDate = new Date();
  const options = { timeZone: "America/New_York" };
  const date = currentDate.toLocaleDateString("en-US", options);
  const time = currentDate.toLocaleTimeString("en-US", options);
  let documentId;
  let newData;

  if (data) {
    documentId =
      data.firstName +
      (data.middleName ?? "") +
      data.lastName +
      "_" +
      date +
      "_" +
      time;
    newData = {
      documentId,
      date,
      time,
      firstName: data.firstName,
      middleName: data.middleName || "",
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      error: error.toString(),
    };
  } else if (path) {
    documentId = path + "_" + date + "_" + time;
    newData = {
      path,
      error,
      date,
      time,
    };
  }

  const docRef = doc(firestore, collectionName, documentId);

  try {
    console.log(docRef);
    console.log(newData);
    await setDoc(docRef, newData);
    console.log("Error logged successfully");
  } catch (error) {
    console.error(" logging error failed. ", error);
  }
};
