import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom";
import careerImage from "../../assets/home/Career.png";
import HireUsImage from "../../assets/home/HireUs.png";
import bg2 from "../../assets/bg/career.svg";
import bg from "../../assets/bg/hireus.svg";
const Contact = () => {
  return (
    <>
      <div id="HIRE-US " className=" pb-8">
        <div
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          {" "}
          <div className="lg:hidden flex justify-center my-4">
            <h3 className="border-secondary l rounded-3xl inline-block p-2 px-5 mt-5 font-serif font-semibold border">
              Partnership
            </h3>
          </div>
          <div className=" lg:flex-row lg:px-32 flex flex-col-reverse items-center px-4">
            <div className=" flex-1 p-4 pt-0">
              <div className="lg:flex justify-start hidden my-4">
                <h3 className="border-secondary l rounded-3xl inline-block p-2 px-5 mt-5 font-serif font-semibold border">
                  Partnership
                </h3>
              </div>
              <h3 className="lg:text-3xl text-[#4BB215] md:text-2xl lg:text-start mb-8 font-serif text-xl text-center">
                Your Trusted Partner in Healthcare Staffing Solutions
              </h3>
              <p className="text-secondary text-[18px] flex-1 mb-8 leading-8 text-justify">
                When it comes to healthcare staffing, we are your trusted
                partners. Our team of professionals is dedicated to providing
                the highest level of service and support. Let us help you find
                the right talent for your healthcare needs.
              </p>
              <div className=" lg:justify-start flex justify-center">
                <Link to="/hire-us">
                  <Button className="mx-auto">Hire Us</Button>
                </Link>
              </div>
            </div>
            <div className=" flex flex-1">
              <img src={HireUsImage} alt="" className=" w-[99%] ml-auto" />
            </div>
          </div>
        </div>
        <div
          id="CAREER"
          className="lg:flex-row lg:px-32 flex flex-col items-center px-4 align-middle"
          style={{
            backgroundImage: `url(${bg2})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          <div className="lg:hidden flex justify-center my-4">
            <h3 className="border-secondary l rounded-3xl inline-block p-2 px-5 mt-5 font-serif font-semibold border">
              Career
            </h3>
          </div>
          <div className=" lg:mt-2 flex items-center flex-1">
            <img
              src={careerImage}
              alt=""
              className=" w-[78%]  mx-auto lg:mr-auto"
            />
          </div>
          <div className="flex-1 p-4 pt-0">
            <div className="lg:flex justify-start hidden my-4">
              <h3 className="border-secondary l rounded-3xl inline-block p-2 px-5 mt-5 font-serif font-semibold border">
                Career
              </h3>
            </div>
            <h3 className="lg:text-3xl md:text-2xl text-[#001991] lg:text-start lg:mt-0 mt-5 mb-8 font-serif text-xl text-center">
              Empowering Healthcare: Join the Passionate Professionals at HPC
              Medical Staffing
            </h3>
            <p className="text-secondary text-[18px] flex-1 mb-8 leading-8 text-justify">
              At HPC Medical Staffing, we are dedicated to building a team of
              passionate and skilled professionals who are committed to
              providing exceptional healthcare services. As we continue to grow,
              we are always looking for talented individuals to join our team.
            </p>
            <div className="lg:justify-start flex justify-center">
              <Link to="career">
                <Button className="">Apply Now</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
