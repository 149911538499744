export const NAV_LINKS = [
  { href: "/", label: "Home" },
  { href: "/about-us", label: "About Us" },
  { href: "/testimonials", label: "Testimonials" },
  { href: "/services", label: "Services" },
  { href: "/career", label: "Career" },
  { href: "/hire-us", label: "Hire Us" },
  { href: "/contact", label: "Contact" },
];

export const CONTACT = {
  phoneNumber1: "(+1) 6032758997",
  phoneNumber2: "(+1) 6037167630",
  email: "hpcmedicalstaffing@gmail.com",
  googleMap: "https://maps.app.goo.gl/ncWJf9fFs4HjXsmd8?g_st=iw",
  Linkedin: "",
  instagram:
    "https://www.instagram.com/hpcmedicalstaffing?igsh=MW40Y2R5aWxnZXhqNA==",
  facebook: "https://www.facebook.com/100086173968700/",
};

export const SERVICES = [
  {
    id: 1,
    name: "Primary Care",
    description:
      "Comprehensive healthcare for all ages, from routine check-ups to managing chronic conditions.",
    icon: "",
  },
  {
    id: 2,
    name: "Specialty Care",
    description:
      "Expert medical care tailored to specific health needs, provided by specialists in various fields.",
    icon: "",
  },
  {
    id: 3,
    name: "Preventive Screenings",
    description:
      "Early detection and prevention of health issues through a variety of diagnostic tests and assessments.",
    icon: "",
  },
  {
    id: 4,
    name: "Wellness Programs",
    description:
      "Holistic programs designed to promote physical, mental, and emotional well-being through education and lifestyle interventions.",
    icon: "",
  },
];

export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const ITEMS_PER_PAGE = 20;
