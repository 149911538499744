import React from "react";

function AddressForm({ handleChange, handleBlur, values, errors }) {
  return (
    <>
      <div className="mb-4">
        <label className="text-grey-darker block mb-2 text-sm font-bold">
          Address:
        </label>
        <textarea
          className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
          type="text"
          name="address"
          placeholder="Street Address *"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.address}
          maxLength={150}
        />
        {errors.address && (
          <p className="-mt-1 text-sm italic text-red-500">{errors.address}</p>
        )}
      </div>
      <div className="md:flex md:space-x-3 md:space-y-0 block space-y-3">
        <div className="flex-1">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            City:
          </label>
          <input
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            type="text"
            name="city"
            placeholder="City *"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            maxLength={50}
          />
          {errors.city && (
            <p className="text-sm italic text-red-500">{errors.city}</p>
          )}
        </div>
        <div className="flex-1">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            State:
          </label>
          <input
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            type="text"
            name="state"
            placeholder="State/Province/Region *"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.state}
            maxLength={50}
          />
          {errors.state && (
            <p className="text-sm italic text-red-500">{errors.state}</p>
          )}
        </div>
        <div className="flex-1">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            ZIP/Postal Code:
          </label>
          <input
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            type="text"
            name="pinCode"
            placeholder="ZIP/Postal Code *"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.pinCode}
            maxLength={5}
          />
          {errors.pinCode && (
            <p className="text-sm italic text-red-500">{errors.pinCode}</p>
          )}
        </div>
      </div>
    </>
  );
}

export default AddressForm;
