import React, { useEffect, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > window.innerHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    isVisible && (
      <div
        onClick={scrollToTop}
        className="scroll-to-top text-white hover:bg-[#4BB215] size-10 lg:bottom-24 sm:bottom-32 right-5 bottom-20 fixed z-50 flex items-center justify-center text-2xl bg-[#001991] rounded-full cursor-pointer">
        <IoIosArrowUp className="size-6" />
      </div>
    )
  );
};

export default ScrollToTop;
