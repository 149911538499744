import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import CareerForm from "./pages/CareerForm";
import Admin from "./pages/Admin";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import ContactForm from "./pages/Contact";
import HireUsForm from "./pages/HireUs";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import Navbar from "./components/UI/Navbar";
import Footer from "./components/UI/Footer";
import ErrorPage from "./pages/ErrorPage";
import ApplicationData from "./pages/ApplicationData";
import ApplicationList from "./pages/ApplicationList";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import CookieNotification from "./components/CookieNotification";
import CookiePolicy from "./pages/CookiePolicy";
import Testimonials from "./components/UI/Testimonials";

const App = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/testimonials"
          element={
            <>
              <Navbar />
              <Testimonials />
              <Footer />
            </>
          }
        />

        <Route
          path="/terms-of-service"
          element={
            <>
              <Navbar />
              <TermsAndConditions />
              <Footer />
            </>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <>
              <Navbar />
              <PrivacyPolicy />
              <Footer />
            </>
          }
        />
        <Route
          path="/about-us"
          element={
            <>
              <Navbar />
              <AboutUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/services"
          element={
            <>
              <Navbar />
              <Services />
              <Footer />
            </>
          }
        />
        <Route
          path="/hire-us"
          element={
            <>
              <Navbar />
              <HireUsForm />
              <Footer />
            </>
          }
        />
        <Route
          path="/career"
          element={
            <>
              <Navbar />
              <CareerForm />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Navbar />
              <ContactForm />
              <Footer />
            </>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/contact-list"
          element={
            <PrivateRoute>
              <ApplicationList />
            </PrivateRoute>
          }
        />
        <Route
          path="/employment-list"
          element={
            <PrivateRoute>
              <ApplicationList />
            </PrivateRoute>
          }
        />
        <Route
          path="/employer-list"
          element={
            <PrivateRoute>
              <ApplicationList />
            </PrivateRoute>
          }
        />
        <Route
          path="/employer-application/:id"
          element={
            <PrivateRoute>
              <ApplicationData />
            </PrivateRoute>
          }
        />
        <Route
          path="/employment-application/:id"
          element={
            <PrivateRoute>
              <ApplicationData />
            </PrivateRoute>
          }
        />
        <Route
          path="/contact-application/:id"
          element={
            <PrivateRoute>
              <ApplicationData />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <CookieNotification />
    </>
  );
};

export default App;
