import React from "react";
import HeroImg from "../../assets/home/Hero.png";
import "../../index.css";
import bg from "../../assets/bg/heroReverse.svg";
import { Link } from "react-router-dom";
import Button from "./Button";
const Hero = () => {
  return (
    <div
      id="HOME"
      className=" lg:px-32 bg-opacity-5 flex flex-col items-center justify-center px-4 py-4"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}>
      <h1 className="border-secondary l rounded-3xl inline-block p-2 px-5 mt-5 font-medium border">
        Welcome to HPC Medical Staffing
      </h1>
      <h2 className="lg:text-[38px] font-serif mt-5 text-2xl md:text-2xl lg:leading-normal text-primary font-thin text-center">
        Where compassion meets excellence
      </h2>
      <p className=" lg:text-[22px] text-secondary   text-center">
        Together, we're committed to your well being - every step of the way
      </p>
      <div className=" z-10 flex items-center justify-center gap-10 mt-12">
        <Link to="/hire-us">
          <Button>Hire Us</Button>
        </Link>
        <Link to="/career">
          <Button>Join Us</Button>
        </Link>
      </div>
      <img
        src={HeroImg}
        alt="Group of doctors"
        className="w-[100%] lg:-mt-16 lg:w-[80%]"
      />
      <div className=""></div>
    </div>
  );
};

export default Hero;
