import React, { useEffect, useState } from "react";
import { CONTACT, NAV_LINKS } from "../../constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaXmark } from "react-icons/fa6";

import logo from "../../assets/hpc logo.jpg";
import Button from "./Button";

const Navbar = () => {
  const [isMenu, setIsMenu] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    const path = location.pathname.slice(1);
    const pageTitle = location.pathname === "/" ? "home" : path;
    document.title = `HPC Medical Staffing | ${pageTitle}`;
  }, [location.pathname]);

  const handleOnClick = (href) => {
    setIsMenu(false);
    if (location.pathname === href) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else navigate(href);
  };

  return (
    <>
      <div className="lg:flex hidden text-primary  bg-[#F8F8F8] justify-between px-6 py-3">
        <a
          href="https://www.google.com/maps?q=58+Hunters+Village+Way,+Manchester,+NH+03103,+USA"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-hover font-semibold">
          <p> 58 Hunters Village Way,Manchester, NH, 03103, USA</p>
        </a>
        <div className="flex gap-2">
          <a
            href={`tel:${CONTACT.phoneNumber1}`}
            className=" hover:text-hover flex items-center gap-2">
            <span className="text-primary font-semibold">Phone: </span>
            {CONTACT.phoneNumber1},
          </a>
          <a
            href={`tel:${CONTACT.phoneNumber2}`}
            className=" hover:text-hover flex items-center gap-2">
            {CONTACT.phoneNumber2}
          </a>
        </div>
      </div>
      <nav
        className={`lg:px-8  lg:items-center sticky top-0 z-50 flex justify-between p-4 bg-white  ${
          isMenu ? "" : "shadow-lg"
        }`}>
        <NavLink to="/" className=" flex items-center gap-2">
          <img src={logo} alt="" className="size-12" />
          <p className="font-serif text-[#001991] lg:text-xl font-semibold">
            HPC Medical Staffing
          </p>
        </NavLink>
        <ul
          className={`lg:flex hidden flex-col lg:flex-row mx-auto lg:mx-0 font-mulish text-center transition-all duration-300 lg:items-center  lg:justify-around 
             lg:gap-8 gap-4 font-semibold`}>
          {NAV_LINKS.map((link) => (
            <li
              key={link.label}
              onClick={() => {
                handleOnClick(link.href);
              }}
              className={`hover:text-green-500 cursor-pointer ${
                location.pathname === link.href ? "text-green-500" : ""
              }`}>
              {link.label === "Contact" ? (
                <Button className="text-[18px]">{link.label}</Button>
              ) : (
                link.label
              )}
            </li>
          ))}
        </ul>
        <div className="lg:hidden w-6">
          {isMenu ? (
            <FaXmark
              className=" hover:text-green size-6 cursor-pointer"
              onClick={() => {
                setIsMenu(false);
              }}
            />
          ) : (
            <GiHamburgerMenu
              className=" hover:text-green size-5 cursor-pointer"
              onClick={() => {
                setIsMenu(true);
              }}
            />
          )}
        </div>
      </nav>
      {/* for mbl */}
      <div
        className={`fixed shadow-xl z-40 pb-4 top-0 bg-white transition-all duration-300  left-0  w-screen ${
          isMenu ? "translate-y-20" : "-translate-y-80"
        } `}>
        <ul
          className={`flex flex-col lg:flex-row mx-auto lg:mx-0 font-mulish text-center transition-all duration-300 lg:items-center  lg:justify-around ${
            isMenu ? "h-70" : "-translate-y-74 lg:translate-y-0 h-10 lg:h-auto"
          } lg:gap-8 gap-4 font-semibold`}>
          {NAV_LINKS.map((link) => (
            <li
              onClick={() => {
                handleOnClick(link.href);
              }}
              key={link.label}
              className={`hover:text-green-500 ${
                location.pathname === link.href ? "text-green-500" : ""
              }`}>
              {link.label === "Contact" ? (
                <Button>{link.label}</Button>
              ) : (
                link.label
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
