import React from "react";

function EducationForm({ handleChange, handleBlur, values, errors }) {
  return (
    <>
      <div className="md:flex mb-6 -mx-3">
        <div className="md:w-full md:mb-0 md:skew-y-0 flex flex-col justify-between px-3 mb-2 space-y-3">
          <div>
            <label className="text-grey-darker block mb-2 text-sm font-bold">
              School Name
            </label>
            <textarea
              className="text-grey-darker capitalize focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
              id="school-name"
              type="text"
              name="schoolName"
              placeholder="School Name *"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.schoolName}
              maxLength={150}
            />
            {errors.schoolName && (
              <p className="-mt-1 text-sm italic text-red-500">
                {errors.schoolName}
              </p>
            )}
          </div>
          <div className="md:flex mb-2 -mx-3">
            <div className="md:w-full px-3">
              <label className="text-grey-darker block mb-2 text-sm font-bold capitalize">
                Degree
              </label>
              <input
                className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
                id="degree"
                type="text"
                name="degree"
                placeholder="Degree *"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.degree}
                maxLength={100}
              />
              {errors.degree && (
                <p className="text-sm italic text-red-500">{errors.degree}</p>
              )}
            </div>
          </div>
        </div>
        <div className="md:w-full px-3">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Special Skills
          </label>
          <input
            className="text-grey-darker  mb-2 focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            id="specialSkills"
            type="text"
            name="specialSkills1"
            placeholder="Special skills 1"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.specialSkills1 ?? ""}
            maxLength={50}
          />
          <input
            className="text-grey-darker my-2 focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            id="specialSkills"
            type="text"
            name="specialSkills2"
            placeholder="Special skills 2"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.specialSkills2 ?? ""}
            maxLength={50}
          />
          <input
            className="text-grey-darker my-2 focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            id="specialSkills"
            type="text"
            name="specialSkills3"
            placeholder="Special skills 3"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.specialSkills3 ?? ""}
            maxLength={50}
          />
        </div>
      </div>
    </>
  );
}

export default EducationForm;
