import { useState, useEffect } from "react";
import { validate } from "../functions/Validate";
import DOMPurify from "dompurify";

function useFormValidation(initialState, onSubmit) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);

  // Constants for field names
  const fieldNames = {
    numberFields: [
      "phoneNumber",
      "employer_number",
      "referenceNumber2",
      "referenceNumber3",
      "referenceNumber",
    ],
    digitFields: ["pinCode", "lastSalary", "working_hoursPerWeek"],
    nameFields: [
      "firstName",
      "lastName",
      "middleName",
      "referenceName",
      "referenceName2",
      "referenceName3",
    ],
    address: ["state", "city"],
  };

  // Constants for regex patterns
  const regexPatterns = {
    numberRegex: /^[\d+]*$/,
    digitRegex: /^\d+$/,
    textOnlyRegex: /^[a-zA-Z\s]*$/,
    addressRegex: /^[a-zA-Z\s,.]*$/,
  };

  useEffect(() => {
    if (isSubmitting) {
      const validationErrors = validate(values, initialState);
      if (Object.keys(validationErrors).length === 0) {
        onSubmit(values);
        console.log("Form Submitted");
        setSubmitting(false);
      }
      setSubmitting(false);
    }
  }, [errors, isSubmitting, initialState, values]);

  function handleChange(event) {
    const { name, value, type, checked } = event.target;

    // Sanitize the input value
    const sanitizedValue = DOMPurify.sanitize(value);

    // Determine the new value based on the input type and field name
    const newValue =
      type === "checkbox"
        ? checked
        : fieldNames.numberFields.includes(name)
        ? regexPatterns.numberRegex.test(sanitizedValue)
          ? sanitizedValue
          : ""
        : fieldNames.digitFields.includes(name)
        ? regexPatterns.digitRegex.test(sanitizedValue)
          ? sanitizedValue
          : ""
        : fieldNames.nameFields.includes(name)
        ? regexPatterns.textOnlyRegex.test(sanitizedValue)
          ? sanitizedValue
          : ""
        : fieldNames.address.includes(name)
        ? regexPatterns.addressRegex.test(sanitizedValue)
          ? sanitizedValue
          : ""
        : sanitizedValue;

    setValues({ ...values, [name]: newValue });
  }

  const resetForm = () => {
    setValues(initialState);
  };

  function handleBlur() {
    // const validationErrors = validate(values, initialState);
    // setErrors(validationErrors);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const validationErrors = validate(values, initialState);
    setErrors(validationErrors);
    setSubmitting(true);
  }

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting,
    resetForm,
  };
}

export default useFormValidation;
