// TODO
//error handling
import React, { useState, useEffect } from "react";
import useFormValidation from "../hooks/Form-validation";
import ContactForm from "../components/forms/ContactForm";
import { EMPLOYER_INITIAL_STATE } from "../constants/Initial-State";
import { createData } from "../firebase/database";
import AddressForm from "../components/forms/AddressForm";
import { v4 as uuid } from "uuid";
import EmployerDetailsForm from "../components/forms/EmployerDetailsForm";
import UploadingModal from "../components/modal/Uploading";
import Button from "../components/UI/Button";
import ScrollToTop from "../components/ScrollToTop";
import bg2 from "../assets/bg/career.svg";
import bg from "../assets/bg/hireus.svg";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();
logEvent(analytics, "Hire Us page");
const HireUsForm = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false); // for button error

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFormSubmit = async (formValues) => {
    const id = uuid();
    setIsError(false);
    setIsModal(true);
    setIsUploading(true);
    setIsSubmit(false);
    const result = await createData(formValues, "Employee-List", id);
    // TODO error handling
    if (result) {
      resetForm();
      setIsUploading(false);
    } else {
      setIsUploading(false);

      setIsError(true);
    }
  };
  const {
    handleSubmit,
    handleChange,
    resetForm,
    values,
    errors,
    isSubmitting,
  } = useFormValidation(EMPLOYER_INITIAL_STATE, handleFormSubmit);

  const submitForm = (event) => {
    setIsSubmit(true);
    event.preventDefault();
    handleSubmit(event);
  };

  const handleClose = () => {
    setIsModal(false);
  };

  return (
    <>
      <ScrollToTop />
      {isModal && (
        <UploadingModal
          onClose={handleClose}
          uploadStatus={isUploading}
          error={isError}
        />
      )}
      <div
        className="lg:mx-60 lg:px-6 bg-opacity-30 flex flex-col items-center justify-center py-8 mx-4 mb-6 bg-white rounded-lg shadow-xl"
        style={{
          background:
            "linear-gradient(60deg, rgba(69, 235, 175, 0.3) 0%, rgba(37, 153, 229, 0.3) 100%, rgba(0, 25, 145, 0.3) 100%)",
        }}>
        <h2 className="lg:text-4xl mb-6 font-serif text-3xl font-medium text-center text-gray-800">
          Hire Us
        </h2>
        <div>
          <h3 className="mb-2 text-xl font-medium text-center">
            Empowering Medical Professionals. Transforming Patient Care.
          </h3>
          <h4 className="text-secondary text-center">
            Hire Us Today for Unmatched Healthcare Staffing Solutions!
          </h4>
        </div>
      </div>

      <form onSubmit={submitForm} className=" px-1 mb-6">
        <div
          className="lg:px-60 lg:space-y-4 px-4 pt-8 pb-4"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          <div className="flex flex-col px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
            <h3 className=" mb-4 text-xl font-bold">Contact</h3>
            <ContactForm
              handleChange={handleChange}
              values={values}
              errors={errors}
            />
          </div>
          <div className="flex flex-col px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
            <h3 className=" mb-4 text-xl font-bold">Address</h3>
            <AddressForm
              handleChange={handleChange}
              values={values}
              errors={errors}
            />
          </div>
        </div>
        <div
          className="lg:px-60 lg:space-y-4 px-4 pt-1"
          style={{
            backgroundImage: `url(${bg2})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          <div className="flex flex-col px-8 pt-6 pb-8 my-2 mb-4 bg-white rounded shadow-md">
            <EmployerDetailsForm
              handleChange={handleChange}
              values={values}
              errors={errors}
            />
            <div className="flex">
              <Button type="submit" className="mx-auto" disabled={isSubmitting}>
                Submit
              </Button>
            </div>
            {Object.keys(errors).length !== 0 && isSubmit && (
              <p className="mt-1 italic font-medium text-center text-red-500">
                Please fill all the required fields.
              </p>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default HireUsForm;
