import React from "react";
import "../styles/index.css";
const Card = ({ name, description, role }) => {
  const splitName = name?.split(" ");
  const initials =
    splitName?.length === 1
      ? splitName[0][0]
      : splitName[0][0] + splitName[1][0];

  return (
    <div className="hover:-translate-y-5 m max-w-md mb-24 transition-all duration-300 cursor-default">
      <div className="main relative text-[17px] text-secondary p-8">
        <p>{description}</p>

        <div className="flex gap-2 text-black absolute items-center -bottom-[80px] left-2">
          <div className="size-14 bg-opacity-70 flex items-center justify-center overflow-hidden font-serif border-2 rounded-full shadow-lg">
            {initials}
          </div>
          <div className="">
            <p className=" font-semibold text-[17px]">{name}</p>
            <p className=" font-medium text-blue-800">{role}</p>
          </div>
        </div>
        <div className="shape  -bottom-[12px] bg-white left-6 absolute  w-6 h-6 "></div>
      </div>
    </div>
  );
};

export default Card;
