import React from "react";
import classNames from "classnames";

const Button = ({ className, children, onClick, disabled }) => {
  return (
    <button
      className={classNames(
        "text-white   font-bold py-1 lg:py-2 lg:px-5 px-3 rounded-3xl hover:scale-90 transition-all duration-200",
        className
      )}
      onClick={onClick}
      disabled={disabled}
      style={{
        background:
          "linear-gradient(60deg, #45EBAF 0%, #2599E5  100%, #001991 100%)",
      }}>
      {children}
    </button>
  );
};

export default Button;
