import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { readData } from "../firebase/database";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaArrowLeft } from "react-icons/fa6";
import LoadingModal from "../components/modal/LoadingModal";
import Table from "../components/UI/Table";
import Sidebar from "../components/UI/Sidebar";
import ScrollToTop from "../components/ScrollToTop";
import logo from "../assets/hpc logo.jpg";

const ApplicationList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilter, setIsFilter] = useState(false);
  const [error, setError] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const { pathname } = useLocation();

  let path, formName, listPath;

  if (pathname === "/contact-list") {
    path = "Contact-List";
    listPath = "contact-application";
    formName = "Contact";
  }

  if (pathname === "/employer-list") {
    path = "Employee-List";
    listPath = "employer-application";
    formName = "Employer";
  }
  if (pathname === "/employment-list") {
    path = "Employment-List";
    listPath = "employment-application";
    formName = "Employment";
  }

  const navigate = useNavigate();
  useEffect(() => {
    if (path) {
      handleFetchList();
    }
  }, [path]);

  const handleFetchList = async () => {
    try {
      setIsLoading(true);
      const data = await readData(path);
      if (!data.length === 1 && data[0] === "No data available") {
      }
      const dataArray = Object.entries(data).map(([key, value]) => ({
        ...value,
      }));

      setData(dataArray);
      setIsLoading(false);
      setError(null);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleFilter = (data) => {
    setSortedData(data);
  };

  const handleOnClick = (id) => {
    navigate(`/${listPath}/${id}`);
  };
  return (
    <>
      <ScrollToTop />

      {isLoading && <LoadingModal />}
      {!isLoading && (
        <div className=" flex">
          <div className="flex flex-col flex-1 px-4 text-center">
            <div className=" lg:px-4 flex items-start lg:pl-4 lg:pr-[56px] justify-center py-8">
              <Link
                to={-1}
                className="text-accent hover:text-hover lg:items-center inline-flex mb-4 space-x-1 text-sm font-medium">
                <FaArrowLeft className="size-4" />
                <span className="font-medium">Back</span>
              </Link>
              <NavLink
                to="/"
                className="lg:mx-auto flex items-center gap-2 ml-auto">
                <img src={logo} alt="" className="size-12" />
                <p className=" lg:block hidden font-serif font-semibold">
                  HPC Medical Staffing
                </p>
              </NavLink>
              <GiHamburgerMenu
                onClick={() => {
                  setIsFilter(true);
                }}
                className=" lg:hidden size-5 inline-block ml-auto cursor-pointer"
              />
            </div>
            <h1 className="mb-4 text-2xl font-bold text-center">
              Submitted {formName} Applications
            </h1>
            <Table data={sortedData} onClick={handleOnClick} />
          </div>

          {/* SIDEBAR  */}
          <Sidebar
            role={pathname === "/employment-list" ? true : false}
            data={data}
            onFilter={handleFilter}
            isFilter={isFilter}
            setIsFilter={(data) => {
              setIsFilter(data);
            }}
          />
        </div>
      )}
    </>
  );
};

export default ApplicationList;
