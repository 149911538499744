import React from "react";

const FormField = ({ label, value, index }) => (
  <div className=" flex flex-col pb-4 border-b border-gray-300">
    <label className="mb-2 text-gray-700">
      {index + 1}. {label}
    </label>
    <p
      className="text-balance font-bold text-gray-600"
      style={{ overflowWrap: "break-word" }}>
      {value}
    </p>
  </div>
);

const FormPaper = ({ data, labels }) => {
  const labelEntries = Object.entries(labels);

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <div className="lg:grid-cols-2 grid grid-cols-1 gap-6">
        {labelEntries.map(([key, value], index) => (
          <FormField key={key} label={value} index={index} value={data[key]} />
        ))}
      </div>
    </div>
  );
};

export default FormPaper;
