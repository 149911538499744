const FIELD_TYPES = {
  nameFields: [
    "firstName",
    "lastName",
    "middleName",
    "referenceName",
    "referenceName2",
    "referenceName3",
  ],
  phoneFields: [
    "phoneNumber",
    "employer_number",
    "referenceNumber",
    "referenceNumber2",
    "referenceNumber3",
  ],
  pinCodeFields: ["pinCode"],
  textFields: [
    "city",
    "state",
    "schoolName",
    "degree",
    "specialSkills1",
    "specialSkills2",
    "specialSkills3",
    "employer",
    "jobTitle",
    "specificDuties",
    "reasonForLeaving",
    "positionInCompany",
    "referenceRelationship",
  ],
  workingHoursFields: ["working_hoursPerWeek"],
  salaryFields: ["lastSalary"],
};

const ERROR_MESSAGES = {
  required: "Kindly fill out this field.",
  invalidPhone: "Invalid phone number format.",
  invalidZip: "Invalid zip code format.",
  invalidOnly: "Invalid text format.",
  invalidWorkingHours: "Invalid working hours. ",
  invalidSalary: "Invalid salary. ",
  invalidName: "Name should be at least 3 letters.",
};

const REGEX_PATTERNS = {
  text: /^[a-zA-Z][a-zA-Z.,-_# ]*$/,
  phone: /^\+?\d{10,13}$/,
  zip: /^\d{5}(-\d{4})?$/,
  name: /^[a-zA-Z.,-_# ]+$/,
  workingHours: /^(1[0-4]\d|150)$/,
  salary: /^(?:[1-9]\d{0,8}|1000000000)$/,
};

export function validate(values, formsToValidate) {
  let errors = {};

  const fieldsToValidate = Object.keys(formsToValidate);

  fieldsToValidate.forEach((field) => {
    // Check if the value is a string before trimming
    const trimmedValue =
      typeof values[field] === "string" ? values[field].trim() : values[field];

    if (!trimmedValue) {
      errors[field] = ERROR_MESSAGES.required;
    } else if (
      FIELD_TYPES.phoneFields.includes(field) &&
      !REGEX_PATTERNS.phone.test(trimmedValue)
    ) {
      errors[field] = ERROR_MESSAGES.invalidPhone;
    } else if (
      FIELD_TYPES.pinCodeFields.includes(field) &&
      !REGEX_PATTERNS.zip.test(trimmedValue)
    ) {
      errors[field] = ERROR_MESSAGES.invalidZip;
    } else if (
      FIELD_TYPES.nameFields.includes(field) &&
      !REGEX_PATTERNS.name.test(trimmedValue)
    ) {
      errors[field] = ERROR_MESSAGES.invalidName;
    } else if (
      FIELD_TYPES.textFields.includes(field) &&
      !REGEX_PATTERNS.text.test(trimmedValue)
    ) {
      errors[field] = ERROR_MESSAGES.invalidOnly;
    } else if (
      FIELD_TYPES.workingHoursFields.includes(field) &&
      !REGEX_PATTERNS.workingHours.test(trimmedValue)
    ) {
      errors[field] = ERROR_MESSAGES.invalidWorkingHours;
    } else if (
      FIELD_TYPES.salaryFields.includes(field) &&
      !REGEX_PATTERNS.salary.test(trimmedValue)
    ) {
      errors[field] = ERROR_MESSAGES.invalidSalary;
    } else {
      delete errors[field];
    }
  });

  return errors;
}
