import React, { useEffect, useRef, useState } from "react";
import useFormValidation from "../hooks/Form-validation";
import ContactForm from "../components/forms/ContactForm";
import WorkExperienceForm from "../components/forms/WorkExperienceForm";
import MiscellaneousForm from "../components/forms/MiscellaneousForm";
import { EMPLOYMENT_INITIAL_STATE } from "../constants/Initial-State";
import { createData } from "../firebase/database";
import AddressForm from "../components/forms/AddressForm";
import { Toaster, toast } from "react-hot-toast";
import { MAX_FILE_SIZE } from "../constants";
import { uploadResume } from "../firebase/storage";
import UploadingModal from "../components/modal/Uploading";
import { v4 as uuid } from "uuid";
import EducationForm from "../components/forms/EducationForm";
import PositionForm from "../components/forms/PositionForm";
import Button from "../components/UI/Button";
import ScrollToTop from "../components/ScrollToTop";
import bg2 from "../assets/bg/career.svg";
import bg from "../assets/bg/hireus.svg";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();
logEvent(analytics, "Career page");
const CareerForm = () => {
  const [resume, setResume] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [resumeError, setResumeError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false); // for button error
  const [progress, setProgress] = useState("");

  const formRef = useRef();

  const handleProgress = (progress) => {
    setProgress(progress);
  };
  const handleFormSubmit = async (formValues) => {
    if (resume && isSubmit) {
      setIsSubmit(false);
      setIsModal(true);
      setIsUploading(true);
      setIsError(false);
      const id = uuid();
      const URL = await uploadResume(resume, id, handleProgress, formValues);
      if (URL === "error") {
        setIsError(true);
        setIsUploading(false);
      } else {
        formValues.resumeUrl = URL;
        const result = await createData(formValues, "Employment-List", id);

        if (result) {
          setIsUploading(false);
          setResume(null);
          resetForm();
          formRef.current.reset();
        } else {
          setIsUploading(false);
          setIsError(true);
        }
      }
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    resetForm,
    errors,
    isSubmitting,
  } = useFormValidation(EMPLOYMENT_INITIAL_STATE, handleFormSubmit);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitForm = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    if (!resume) {
      setResumeError("Please upload resume");
    } else setResumeError("");
    handleSubmit(event);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        setResumeError("Invalid file format. Please select a PDF file.");
        toast.error("Invalid file format. Please select a PDF file.");
        setResume(null);
      } else if (file.size > MAX_FILE_SIZE) {
        setResumeError(
          "File size exceeds the 10MB limit. Please select a smaller file."
        );
        setResume(null);
        toast.error(
          "File size exceeds the 10MB limit. Please select a smaller file."
        );
      } else {
        setResume(file);
        setResumeError(""); // clear any previous error
      }
    }
  };

  const handleClose = () => {
    setIsModal(false);
  };

  return (
    <>
      <ScrollToTop />
      <Toaster />

      {isModal && (
        <UploadingModal
          onClose={handleClose}
          uploadStatus={isUploading}
          error={isError}
          progress={progress}
        />
      )}
      <div
        className="lg:mx-60 lg:px-6 bg-opacity-30 flex flex-col items-center justify-center py-8 mx-4 mb-6 bg-white rounded-lg shadow-xl"
        style={{
          background:
            "linear-gradient(60deg, rgba(69, 235, 175, 0.3) 0%, rgba(37, 153, 229, 0.3) 100%, rgba(0, 25, 145, 0.3) 100%)",
        }}>
        <h2 className="lg:text-4xl mb-6 font-serif text-3xl font-medium text-center text-gray-800">
          Career
        </h2>
        <div>
          <h3 className="mb-2 text-xl font-medium text-center">
            Connecting Talent with Opportunity. Building a Healthier Tomorrow.
          </h3>
          <h4 className="text-secondary text-center">
            Join Our Team and Send Your Resume Today
            <br className="lg:block hidden" />
            Together, We Can Make a Difference!
          </h4>
        </div>
      </div>

      <form ref={formRef} onSubmit={submitForm} className=" px-1 mb-6">
        <div
          className="lg:px-60 px-4 pt-8 pb-4 space-y-4"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          <h2 className="lg:text-4xl lg:mb-6 font-serif text-3xl font-medium text-center text-gray-800"></h2>

          <div className="flex flex-col px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
            <h3 className=" mb-4 text-xl font-bold">Contact</h3>
            <ContactForm
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
            />
          </div>

          <div className="flex flex-col px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
            <h3 className=" mb-4 text-xl font-bold">Address</h3>
            <AddressForm
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
            />
          </div>
        </div>
        <div
          className="lg:px-60 px-4 space-y-4"
          style={{
            backgroundImage: `url(${bg2})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          <div className="flex flex-col px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
            <h3 className=" mb-4 text-xl font-bold">Position applying for</h3>
            <PositionForm
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
            />
          </div>
          <div className="flex flex-col px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
            <h3 className=" mb-4 text-xl font-bold">Education</h3>
            <EducationForm
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
            />
          </div>
          <div className="flex flex-col px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
            <h3 className=" mb-4 text-xl font-bold">Work Experience</h3>
            <WorkExperienceForm
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
            />
          </div>
          <div className="flex flex-col px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
            <h3 className=" mb-4 text-xl font-bold">Miscellaneous</h3>
            <MiscellaneousForm
              handleChange={handleChange}
              values={values}
              errors={errors}
            />
          </div>
          <div className="flex flex-col px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
            <h3 className=" mb-4 text-xl font-bold">Resume</h3>
            <div className=" lg:flex items-center space-x-4 text-center align-middle">
              <label
                htmlFor="resume"
                className="mt-1 font-medium text-gray-700">
                Upload Resume:
              </label>
              <input
                id="resume"
                name="resume"
                type="file"
                className="lg:block hidden"
                onChange={handleFileChange}
                accept="application/pdf"
              />
            </div>
            <input
              id="resume"
              name="resume"
              type="file"
              className="lg:hidden "
              onChange={handleFileChange}
              accept="application/pdf"
            />
            {resumeError && (
              <p className="mt-1 ml-[140px] italic font-medium text-red-500">
                {resumeError}
              </p>
            )}
            <div className="w-full px-5 py-8 bg-white rounded shadow-md">
              <h4 className="mb-4 text-lg font-bold text-center text-gray-700">
                Conditions of Hire
              </h4>
              <p className="text-center text-gray-700">
                HPC requires a Criminal record check from NH-Dept of safety
                after being hired. Additionally - HPC requires that all
                employees undergo a blood or urine test as a condition of
                pre-employment screening.
              </p>
            </div>
            <Button
              type="submit"
              disabled={isSubmitting}
              className=" px-4 py-2 mx-auto mt-8">
              Submit
            </Button>
            {Object.keys(errors).length !== 0 && isSubmit && (
              <p className="mt-1 italic font-medium text-center text-red-500">
                Please fill all the required fields.
              </p>
            )}{" "}
            {Object.keys(errors).length === 0 && resumeError && isSubmit && (
              <p className="mt-1 italic font-medium text-center text-red-500">
                Please upload Resume.
              </p>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default CareerForm;
