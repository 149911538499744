import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { createData } from "./database";
import { errorLog } from "./firestore";

export const uploadResume = async (pdfFile, id, onProgress, formData) => {
  return new Promise((resolve) => {
    const storage = getStorage();
    const pdfRef = ref(storage, `resume/${id}.pdf`);
    const uploadTask = uploadBytesResumable(pdfRef, pdfFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
        console.log("Uploading:", progress);
      },
      (error) => {
        console.error("Error uploading PDF:", error);

        errorLog("Resume upload error", formData, error);
        resolve("error");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch((error) => {
            console.error("Error getting download URL:", error);

            errorLog("Resume URL fetch error", formData, error);
            resolve("error");
          });
      }
    );
  });
};

export const deleteResume = async (filePath) => {
  try {
    const storage = getStorage();
    const fileRef = ref(storage, filePath);

    await deleteObject(fileRef);

    console.log(`File deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting file:", error);
    errorLog("resume delete error", null, error, filePath);
    return false;
  }
};
