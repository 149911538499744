import React, { useEffect, useState } from "react";
import { FaXmark } from "react-icons/fa6";
const Sidebar = ({ data, onFilter, isFilter, setIsFilter, role }) => {
  const [sortOrder, setSortOrder] = useState("date");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filterNurse, setFilterNurse] = useState(false);
  const [filterLPNurse, setFilterLPNurse] = useState(false);
  const [filterLNACNA, setFilterLNACNA] = useState(false);

  const today = new Date();
  const maxDate = today.toISOString().split("T")[0];

  useEffect(() => {
    const sortedData = data
      ?.filter((app) => {
        const formattedName = `${app.firstName ?? ""} ${app.middleName ?? ""} ${
          app.lastName ?? ""
        }`.toLowerCase();

        const formattedSearchTerm = searchTerm?.toLowerCase() ?? "";

        const createFrequencyMap = (str) => {
          return [...str].reduce((acc, char) => {
            acc[char] = (acc[char] || 0) + 1;
            return acc;
          }, {});
        };

        const nameFrequencyMap = createFrequencyMap(formattedName);
        const searchTermFrequencyMap = createFrequencyMap(formattedSearchTerm);

        return Object.keys(searchTermFrequencyMap).every(
          (char) =>
            nameFrequencyMap[char] &&
            nameFrequencyMap[char] >= searchTermFrequencyMap[char]
        );
      })
      .filter((app) => {
        const appDate = new Date(app.date.replace(/-/g, "/"));
        const start = startDate ? new Date(startDate.replace(/-/g, "/")) : null;
        const end = endDate ? new Date(endDate.replace(/-/g, "/")) : null;

        if (!start && !end) return true;
        if (start && !end) return appDate >= start;
        if (!start && end) return appDate <= end;
        return appDate >= start && appDate <= end;
      })
      .filter((app) => !filterNurse || app?.registered_nurse === "Yes")
      .filter((app) => !filterLPNurse || app?.lp_nurse === "Yes")
      .filter((app) => !filterLNACNA || app?.lna_cna === "Yes")
      .sort((a, b) => {
        if (sortOrder === "name") {
          const nameA = `${a?.firstName ?? ""} ${a?.middleName ?? ""} ${
            a?.lastName ?? ""
          }`;
          const nameB = `${b?.firstName ?? ""} ${b?.middleName ?? ""} ${
            b?.lastName ?? ""
          }`;
          console.log();
          return nameA.localeCompare(nameB);
        } else {
          // Convert date and time to Date objects
          const dateTimeA = new Date(`${a.date} ${a.time}`);
          const dateTimeB = new Date(`${b.date} ${b.time}`);

          // Sort in descending order
          return dateTimeB - dateTimeA;
        }
      });

    onFilter(sortedData);
  }, [
    data,
    searchTerm,
    startDate,
    endDate,
    filterNurse,
    filterLPNurse,
    filterLNACNA,
    sortOrder,
  ]);

  const handleDateClear = () => {
    setStartDate("");
    setEndDate("");
  };
  return (
    <div
      className={`fixed lg:sticky transition-all pb-8 duration-300  top-1 right-0 w-[300px] min-h-screen max-h-screen overflow-y-auto p-4 bg-gray-100 ${
        isFilter ? "" : "translate-x-80 lg:translate-x-0"
      }`}>
      <div className="lg:hidden flex justify-end">
        <FaXmark
          className="size-6 my-2"
          onClick={() => {
            setIsFilter(false);
          }}
        />
      </div>
      <div className="mb-4">
        <p className="bg-formTitle px-2 py-1 mb-1 text-center text-white rounded-lg">
          Filter by Name
        </p>
        <input
          type="text"
          className="w-full px-3 py-2 mt-2 bg-gray-200 rounded-lg focus:outline-none focus:shadow-outline  focus:shadow-[#000E66] focus:shadow-md  border shadow appearance-none"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by name"
        />
      </div>

      <hr className="my-4" />

      <div className="mb-4">
        <p className="bg-formTitle px-2 py-1 mb-1 text-center text-white rounded-lg">
          Filter by Date
        </p>
        <p className="mb-1">From:</p>
        <input
          type="date"
          className="w-full px-3 py-2 mt-2 bg-gray-200 rounded-lg focus:outline-none focus:shadow-outline  focus:shadow-[#000E66] focus:shadow-md  border shadow appearance-none"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Start Date"
          max={maxDate}
        />
      </div>
      <div className="mb-4">
        <p className="mb-1">To:</p>
        <input
          type="date"
          className=" w-full px-3 py-2 mt-2 bg-gray-200 rounded-lg focus:outline-none focus:shadow-outline  focus:shadow-[#000E66] focus:shadow-md  border shadow appearance-none"
          value={endDate ? endDate : maxDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="End Date"
          min={startDate ? startDate : null}
          max={maxDate}
        />
      </div>
      <button
        className="hover:bg-green-800 border-secondary px-3 py-1 border rounded-lg"
        onClick={handleDateClear}>
        Clear
      </button>
      <hr className="my-4" />

      {role && (
        <div className="mb-4">
          <p className="bg-formTitle px-2 py-1 mb-1 text-center text-white rounded-lg">
            Filter by Role
          </p>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              className=" mr-2"
              checked={filterNurse}
              onChange={(e) => setFilterNurse(e.target.checked)}
            />
            <label className="select-none">Registered Nurse</label>
          </div>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              className=" mr-2"
              checked={filterLPNurse}
              onChange={(e) => setFilterLPNurse(e.target.checked)}
            />
            <label className="select-none">LP Nurse</label>
          </div>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              className=" mr-2"
              checked={filterLNACNA}
              onChange={(e) => setFilterLNACNA(e.target.checked)}
            />
            <label className="select-none">LNA/CNA</label>
          </div>
        </div>
      )}

      <hr className="my-4" />

      <div className="mb-4">
        <p className="bg-formTitle px-2 py-1 mb-1 text-center text-white rounded-lg">
          Sort by
        </p>
        <select
          className=" w-full px-3 py-2 mb-8 lg:mb-0  mt-2 bg-gray-200 rounded-lg focus:outline-none focus:shadow-outline  focus:shadow-[#000E66] focus:shadow-md  border shadow appearance-none"
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}>
          <option value="date">Date</option>
          <option value="name">Name</option>
        </select>
      </div>
    </div>
  );
};

export default Sidebar;
