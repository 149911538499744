import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Hero from "../components/UI/Hero";
import About from "../components/UI/About";
import Service from "../components/UI/Service";
import Contact from "../components/UI/Contact";
import ScrollToTop from "../components/ScrollToTop";
import Testimonials from "../components/UI/Testimonials";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();
logEvent(analytics, "Home page");
const Home = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const testimonialsRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (hash === "#testimonials" && testimonialsRef.current) {
      const position = testimonialsRef.current.offsetTop - 80;
      window.scrollTo({ top: position, behavior: "smooth" });
    }
  }, [hash]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          navigate("/");
        }
      },
      { threshold: 0.1 }
    );

    if (testimonialsRef.current) {
      observer.observe(testimonialsRef.current);
    }

    return () => {
      if (testimonialsRef.current) {
        observer.unobserve(testimonialsRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className=" flex flex-col">
        <ScrollToTop />
        <Hero />
        <About />
        <Service />
        <Testimonials ref={testimonialsRef} />
        <Contact />
      </div>
    </>
  );
};

export default Home;
