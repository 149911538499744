import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { PRIVACY_POLICY } from "../constants/Privacy-poilicy";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();
logEvent(analytics, "Privacy Policy page");
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 py-8 bg-gray-100">
      <div className="w-full p-8 bg-white rounded-lg shadow-md">
        <Link
          to={-1}
          className="hover:text-hover inline-flex items-center mb-4 mr-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-5 h-5 mr-2">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          Back
        </Link>
        <h2 className="mb-4 font-serif text-xl font-bold text-center">
          Privacy Policy
        </h2>
        <p className="mb-2 text-gray-700">Date: 14th May 2024</p>
        <p className="mb-2 text-gray-700">
          This Privacy Policy outlines how HPC Medical Staffing LLC ("HPC")
          collects, uses, and protects your personal information when you use
          our website.
        </p>

        {PRIVACY_POLICY.map((item, index) => (
          <div key={index}>
            <h3 className="mt-4 mb-2 text-lg font-bold">{item.title}</h3>
            <p
              className="mb-2 text-gray-700"
              dangerouslySetInnerHTML={{ __html: item.content }}></p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
