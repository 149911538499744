export const TERMS_OF_SERVICE = [
  {
    title: "Acceptance of Terms",
    content:
      'These Terms and Conditions of Use govern your use of the HPC Medical Staffing LLC ("HPC") website. By accessing this website, you agree to abide by these terms and conditions as well as all applicable laws and regulations.',
  },
  {
    title: "Use of Materials",
    content:
      'The materials contained on the HPC website are provided "as is" for informational purposes only. HPC makes no warranties, expressed or implied, regarding the accuracy, reliability, or completeness of the information provided. HPC reserves the right to modify or remove any content on the website at any time without prior notice.',
  },
  {
    title: "Intellectual Property Rights",
    content:
      "All intellectual property rights related to the content and materials on this website are owned by HPC or its licensors. Users are granted a limited, non-exclusive, and revocable license to access and use the content for personal and non-commercial purposes only.",
  },
  {
    title: "Limitation of Liability",
    content:
      "In no event shall HPC be liable for any damages, including without limitation, damages for loss of data or profit, arising out of the use or inability to use the materials on HPC's website, even if HPC or an authorized representative has been notified orally or in writing of the possibility of such damage.",
  },
  {
    title: "User Conduct",
    content:
      "Users agree to use the website only for lawful purposes and in a manner that does not infringe upon the rights of others. Prohibited activities include but are not limited to unauthorized access, distribution of harmful content, and any actions that could damage the website or interfere with its operation.",
  },
  {
    title: "Links to Third-Party Sites",
    content:
      "HPC's website may contain links to third-party websites for informational purposes. The inclusion of any link does not imply endorsement by HPC of the site. HPC is not responsible for the contents of any linked site. Users access linked websites at their own risk.",
  },
  {
    title: "Indemnification",
    content:
      "Users agree to indemnify and hold HPC harmless from any claims, damages, losses, or liabilities arising from their use of the website or violation of the terms and conditions.",
  },
  {
    title: "Modification of Terms",
    content:
      "HPC reserves the right to modify these Terms and Conditions of Use at any time without notice. By continuing to use this website after any modifications, you agree to be bound by the revised terms.",
  },
  {
    title: "Governing Law",
    content:
      "These Terms and Conditions of Use shall be governed by and construed in accordance with the laws of the State of New Hampshire, USA, without regard to its conflict of law provisions.",
  },
  {
    title: "Dispute Resolution",
    content:
      "Any dispute arising from or relating to these terms and conditions shall be resolved exclusively in the state or federal courts located in New Hampshire, and users consent to the jurisdiction of such courts.",
  },
  {
    title: "Severability",
    content:
      "If any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.",
  },
  {
    title: "Contact Information",
    content:
      "If you have any questions or concerns regarding these Terms and Conditions of Use, please contact us at contact@hpcmedicalstaffing.com.",
  },
];
