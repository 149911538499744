export const TESTIMONIALS = [
  {
    name: "John Smith",
    description:
      "Thanks to HPC Medical staffing, our agency has seen a remarkable improvement in staff efficiency and patient satisfaction. Their prompt and professional service has made staffing challenges a thing of the past! Their unwavering commitment to excellence is truly commendable. ",
    role: "Partner",
  },
  {
    name: "Michael Brown",
    description:
      "As an employee of HPC Medical staffing, I can confidently say that they prioritize both the needs of their clients and their staff. Their supportive environment and focus on professional growth make it a fulfilling experience to be part of the team.",
    role: "Employer",
  },
  {
    name: "Emily Johnson",
    description:
      "As a patient, my experience with HPC Medical staffing has been exceptional. Their efficiency and dedication to patient satisfaction are truly remarkable. The staff’s professionalism and empathy made me feel valued and well taken care of.",
    role: "Patient",
  },
];
