import React from "react";
import LoadingGif from "../../assets/LoadingGif.gif";
const LoadingModal = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <img src={LoadingGif} alt="Loading" className="size-48" />
    </div>
  );
};

export default LoadingModal;
