//TODO
//reference name change

import React from "react";

function WorkExperienceForm({ handleChange, handleBlur, values, errors }) {
  const today = new Date();
  const maxDate = today.toISOString().split("T")[0];

  return (
    <div className="space-y-4">
      <h2 className="mb-4 text-lg font-semibold">
        WORK EXPERIENCE (Most Recent First)
      </h2>
      <div className="md:flex-row md:space-x-4 flex flex-col">
        {/* Employer */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Employer:
          </label>
          <input
            type="text"
            name="employer"
            placeholder="Employer *"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.employer}
            maxLength={150}
          />
          {errors.employer && (
            <p className="text-sm italic text-red-500">{errors.employer}</p>
          )}
        </div>

        {/* Telephone number */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Telephone number:
          </label>
          <input
            type="tel"
            name="employer_number"
            placeholder="Employer number *"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={13}
            value={values.employer_number}
          />
          {errors.employer_number && (
            <p className="text-sm italic text-red-500">
              {errors.employer_number}
            </p>
          )}
        </div>
      </div>

      {/* Address, From (month/year), To (month/year) */}

      {/* Address */}
      <div className="flex-grow">
        <label className="text-grey-darker block mb-2 text-sm font-bold">
          Address:
        </label>
        <textarea
          type="text"
          name="employer_address"
          placeholder="Address *"
          className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.employer_address}
          maxLength={150}
        />
        {errors.employer_address && (
          <p className="-mt-1 text-sm italic text-red-500">
            {errors.employer_address}
          </p>
        )}
      </div>
      <div className="md:flex-row md:space-x-4 flex flex-col">
        {/* From (month/year) */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            From (month/year):
          </label>
          <input
            type="date"
            name="working_from"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.working_from}
            max={maxDate}
          />
          {errors.working_from && (
            <p className="text-sm italic text-red-500">{errors.working_from}</p>
          )}
        </div>

        {/* To (month/year) */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            To (month/year):
          </label>
          <input
            type="date"
            name="worked_until"
            min={values.working_from}
            max={maxDate}
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.worked_until}
          />
          {errors.worked_until && (
            <p className="text-sm italic text-red-500">{errors.worked_until}</p>
          )}
        </div>
      </div>

      {/* Job Title, Hours per week */}
      <div className="md:flex-row md:space-x-4 flex flex-col">
        {/* Job Title */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Job Title:
          </label>
          <input
            type="text"
            name="jobTitle"
            placeholder="Job Title *"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.jobTitle}
            maxLength={100}
          />
          {errors.jobTitle && (
            <p className="text-sm italic text-red-500">{errors.jobTitle}</p>
          )}
        </div>

        {/* Hours per week */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Hours per week:
          </label>
          <input
            type="text"
            placeholder="Hours per week *"
            name="working_hoursPerWeek"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.working_hoursPerWeek}
            max={150}
            maxLength={3}
          />
          {errors.working_hoursPerWeek && (
            <p className="text-sm italic text-red-500">
              {errors.working_hoursPerWeek}
            </p>
          )}
        </div>
      </div>

      {/* Specific Duties, Reason for Leaving */}
      <div className="md:flex-row md:space-x-4 flex flex-col">
        {/* Specific Duties */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Specific Duties:
          </label>
          <textarea
            name="specificDuties"
            placeholder="Specific Duties"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.specificDuties}
            maxLength={100}
          />
          {errors.specificDuties && (
            <p className="-mt-1 text-sm italic text-red-500">
              {errors.specificDuties}
            </p>
          )}
        </div>
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Last Salary ($):
          </label>
          <input
            type="text"
            placeholder="Last Salary *"
            name="lastSalary"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastSalary}
            maxLength={8}
          />
          {errors.lastSalary && (
            <p className="text-sm italic text-red-500">{errors.lastSalary}</p>
          )}
        </div>

        {/* Reason for Leaving */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Reason for Leaving:
          </label>
          <input
            type="text"
            placeholder="Reason for Leaving"
            name="reasonForLeaving"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.reasonForLeaving}
            maxLength={150}
          />
          {errors.reasonForLeaving && (
            <p className="text-sm italic text-red-500">
              {errors.reasonForLeaving}
            </p>
          )}
        </div>
      </div>

      {/* May we contact this employer? */}
      <div className="p-4 border rounded">
        <label className="block mb-2 font-semibold">
          May we contact this employer?
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            name="contactEmployer"
            value="yes"
            id="contactYes"
            className=""
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.contactEmployer === "yes"}
          />
          <label htmlFor="contactYes">Yes</label>
          <input
            type="radio"
            name="contactEmployer"
            value="no"
            id="contactNo"
            className="mr-3"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.contactEmployer === "no"}
          />
          <label htmlFor="contactNo">No</label>
        </div>
        {errors.contactEmployer && (
          <p className="text-sm italic text-red-500">
            {errors.contactEmployer}
          </p>
        )}
      </div>

      {/* Personal Reference Name, Reference Relationship */}
      <div className="md:flex-row md:space-x-4 flex flex-col">
        {/* Personal Reference Name */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Personal Reference Name
          </label>
          <input
            type="text"
            name="referenceName"
            placeholder="Name *"
            className="text-grey-darker capitalize focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.referenceName}
            maxLength={50}
          />
          {errors.referenceName && (
            <p className="text-sm italic text-red-500">
              {errors.referenceName}
            </p>
          )}
        </div>
        {/* Reference Relationship */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Reference Relationship:
          </label>
          <input
            type="text"
            name="referenceRelationship"
            placeholder="Relationship *"
            className="text-grey-darker capitalize focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.referenceRelationship}
            maxLength={50}
          />
          {errors.referenceRelationship && (
            <p className="text-sm italic text-red-500">
              {errors.referenceRelationship}
            </p>
          )}
        </div>
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Telephone Number:
          </label>
          <input
            type="tel"
            name="referenceNumber"
            placeholder="Telephone Number *"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.referenceNumber}
            maxLength={10}
          />
          {errors.referenceNumber && (
            <p className="text-sm italic text-red-500">
              {errors.referenceNumber}
            </p>
          )}
        </div>
      </div>
      <div className="flex-grow">
        <label className="text-grey-darker block mb-2 text-sm font-bold">
          Address:
        </label>
        <textarea
          type="text"
          name="referenceAddress"
          placeholder="Address *"
          className="text-grey-darker capitalize focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.referenceAddress}
          maxLength={150}
        />
        {errors.referenceAddress && (
          <p className="text-sm italic text-red-500">
            {errors.referenceAddress}
          </p>
        )}
      </div>
      <div className="md:flex-row md:space-x-4 flex flex-col">
        {/* Personal Reference Name */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Personal Reference Name
          </label>
          <input
            type="text"
            name="referenceName2"
            placeholder="Name"
            className="text-grey-darker capitalize focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.referenceName2 ?? ""}
            maxLength={50}
          />
          {errors.referenceName2 && (
            <p className="text-sm italic text-red-500">
              {errors.referenceName2}
            </p>
          )}
        </div>
        {/* Reference Relationship */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Reference Relationship:
          </label>
          <input
            type="text"
            name="referenceRelationship2"
            placeholder="Relationship"
            className="text-grey-darker capitalize focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.referenceRelationship2 ?? ""}
            maxLength={50}
          />
          {errors.referenceRelationship2 && (
            <p className="text-sm italic text-red-500">
              {errors.referenceRelationship2}
            </p>
          )}
        </div>
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Telephone Number:
          </label>
          <input
            type="tel"
            name="referenceNumber2"
            placeholder="Telephone Number"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={13}
            value={values.referenceNumber2 ?? ""}
          />
          {errors.referenceNumber2 && (
            <p className="text-sm italic text-red-500">
              {errors.referenceNumber2}
            </p>
          )}
        </div>
      </div>
      <div className="flex-grow">
        <label className="text-grey-darker block mb-2 text-sm font-bold">
          Address:
        </label>
        <textarea
          type="text"
          name="referenceAddress2"
          placeholder="Address"
          className="text-grey-darker capitalize  focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow "
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.referenceAddress2 ?? ""}
          maxLength={150}
        />
        {errors.referenceAddress2 && (
          <p className="text-sm italic text-red-500">
            {errors.referenceAddress2}
          </p>
        )}
      </div>
      <div className="md:flex-row md:space-x-4 flex flex-col">
        {/* Personal Reference Name */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Personal Reference Name
          </label>
          <input
            type="text"
            name="referenceName3"
            placeholder="Name"
            className="text-grey-darker capitalize focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.referenceName3 ?? ""}
            maxLength={50}
          />
          {errors.referenceName3 && (
            <p className="text-sm italic text-red-500">
              {errors.referenceName3}
            </p>
          )}
        </div>
        {/* Reference Relationship */}
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Reference Relationship:
          </label>
          <input
            type="text"
            name="referenceRelationship3"
            placeholder="Relationship"
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 capitalize py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.referenceRelationship3 ?? ""}
            maxLength={50}
          />
          {errors.referenceRelationship2 && (
            <p className="text-sm italic text-red-500">
              {errors.referenceRelationship3}
            </p>
          )}
        </div>
        <div className="flex-grow">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Telephone Number:
          </label>
          <input
            type="tel"
            name="referenceNumber3"
            placeholder="Telephone Number"
            className="text-grey-darker  focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.referenceNumber3 ?? ""}
            maxLength={13}
          />
          {errors.referenceNumber3 && (
            <p className="text-sm italic text-red-500">
              {errors.referenceNumber3}
            </p>
          )}
        </div>
      </div>
      <div className="flex-grow">
        <label className="text-grey-darker block mb-2 text-sm font-bold">
          Address:
        </label>
        <textarea
          type="text"
          name="referenceAddress3"
          placeholder="Address"
          className="text-grey-darker capitalize focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.referenceAddress3 ?? ""}
          maxLength={150}
        />
        {errors.referenceAddress3 && (
          <p className="text-sm italic text-red-500">
            {errors.referenceAddress3}
          </p>
        )}
      </div>
      <div className="p-4 border rounded">
        <div className=" flex items-start align-top">
          <input
            type="checkbox"
            id="authorization"
            name="authorization"
            className="m-2"
            onChange={handleChange}
            checked={values.authorization}
          />
          <label className="block mb-2 font-semibold">
            I here by authorize the above individuals/agencies to release
            information related to my employment to HPC LLC. (HPC Medical
            Staffing)
          </label>
          <div className="flex items-center space-x-4"></div>
        </div>
        {errors.authorization && (
          <p className="lg:ml-7 -mt-1 text-sm italic text-red-500">
            {errors.authorization}
          </p>
        )}
      </div>
    </div>
  );
}

export default WorkExperienceForm;
