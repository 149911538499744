import React from "react";
import AboutImg from "../../assets/home/About.png";
import Button from "./Button";
import { Link } from "react-router-dom";
import bg from "../../assets/bg/about.svg";
const About = () => {
  return (
    <>
      <div
        className="pt-8"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}>
        <div id="ABOUT US " className=" lg:hidden flex justify-center my-4">
          <h3 className="border-secondary l rounded-3xl inline-block p-2 px-5 mt-5 font-serif font-semibold border">
            About Us
          </h3>
        </div>
        <div className="lg:px-32 lg:flex-row flex flex-col-reverse items-center justify-center px-4">
          <div className=" lg:items-start flex flex-col items-center flex-1 p-4">
            <div
              id="ABOUT US "
              className=" lg:justify-start lg:flex hidden my-4">
              <h3 className="border-secondary l rounded-3xl inline-block p-2 px-5 mt-5 font-serif font-semibold border">
                About Us
              </h3>
            </div>
            <h3 className="lg:text-3xl text-[#4BB215] lg:text-start md:text-2xl mb-6 font-serif text-xl text-center">
              Your Partner in Exceptional Healthcare Staffing Solutions
            </h3>
            <p className="text-secondary text-[18px] flex-1 mb-6 leading-8 text-justify">
              HPC Medical Staffing LLC, a New Hampshire-based agency, provides
              top-notch healthcare staffing solutions across major cities in the
              state. Our focus is on ensuring healthcare satisfaction.
            </p>

            <Link to="/about-us" className="">
              <Button>Read More</Button>
            </Link>
          </div>
          <div className=" flex-1">
            <img
              src={AboutImg}
              alt="Nurse shaking hand with elder man"
              className="w-full ml-auto"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
