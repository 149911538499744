import React from "react";

const UploadingModal = ({ progress, uploadStatus, error, onClose }) => {
  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
      <div className="max-w-md p-6 mx-4 bg-white rounded-lg shadow-xl">
        <h2
          className={`text-2xl font-bold text-center ${
            error
              ? "text-red-600"
              : uploadStatus
              ? "text-gray-600"
              : "text-green-600"
          } `}>
          {uploadStatus
            ? "Uploading..."
            : error
            ? "Error uploading"
            : "Uploaded Successfully"}
        </h2>
        {uploadStatus && progress !== undefined && (
          <div className="w-full h-2 mt-2 bg-gray-300 rounded">
            <div
              style={{
                width: `${progress}%`,
                background:
                  "linear-gradient(60deg, #45EBAF 0%, #2599E5  100%, #001991 100%)",
              }}
              className="h-full rounded"></div>
          </div>
        )}

        {!uploadStatus && (
          <div className="flex items-center justify-center mt-4 space-x-4">
            <button
              onClick={onClose}
              className=" focus:outline-none px-4 py-2 text-gray-800 
              hover:scale-90 transition-all duration-300 flex justify-center   text-sm font-medium hover:text-white  hover:bg-[#FF4646]  lg:text-[18px]  rounded-3xl mx-auto border border-secondary shadow-md">
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadingModal;
