import React, { useState } from "react";

const DeleteModal = ({ onDelete, onCancel }) => {
  const [isConfirm, setIsConfirm] = useState(false);
  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
      <div className="max-w-md p-6 mx-4 bg-white rounded-lg shadow-xl">
        <h2 className="mb-4 text-2xl font-bold text-center text-gray-800">
          Confirm Deletion
        </h2>
        {!isConfirm && (
          <p className="mb-6 text-lg text-center text-gray-700">
            Are you sure you want to delete?
          </p>
        )}
        {isConfirm && (
          <p className="mb-6 text-lg text-center text-gray-700">Deleting...</p>
        )}
        <div className="flex items-center justify-center mt-4 space-x-4">
          <button
            onClick={onDelete}
            className="hover:bg-red-600 focus:outline-none px-4 py-2 text-white bg-red-500 rounded">
            Delete
          </button>
          <button
            onClick={onCancel}
            className="hover:bg-gray-400 focus:outline-none px-4 py-2 text-gray-800 bg-gray-300 rounded">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
