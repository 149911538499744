import React from "react";

function ContactForm({ handleChange, handleBlur, values, errors }) {
  return (
    <>
      <div className="mb-4">
        <label className="text-grey-darker block mb-2 text-sm font-bold">
          Name:
        </label>
        <div className="sm:flex-row sm:space-y-0 sm:space-x-3 flex flex-col space-y-3">
          <div className="relative w-full">
            <input
              className="text-grey-darker capitalize w-full px-3 py-2 focus:outline-none focus:shadow-outline  focus:shadow-[#000E66] focus:shadow-md leading-tight border rounded shadow appearance-none"
              type="text"
              name="firstName"
              placeholder="First Name *"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              maxLength={50}
            />
            {errors.firstName && (
              <p className="md:hidden text-sm italic text-red-500">
                {errors.firstName}
              </p>
            )}
          </div>
          <div className=" relative w-full">
            <input
              className="text-grey-darker capitalize w-full px-3 py-2 focus:outline-none focus:shadow-outline  focus:shadow-[#000E66] focus:shadow-md leading-tight border rounded shadow appearance-none"
              type="text"
              name="middleName"
              placeholder="Middle Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.middleName ?? ""}
              maxLength={50}
            />
            {errors.middleName && (
              <p className="-bottom-5 left-1 lg:absolute text-sm italic text-red-500">
                {errors.middleName}
              </p>
            )}
          </div>
          <div className=" relative w-full">
            <input
              className="text-grey-darker capitalize w-full px-3 py-2 focus:outline-none focus:shadow-outline  focus:shadow-[#000E66] focus:shadow-md leading-tight border rounded shadow appearance-none"
              type="text"
              name="lastName"
              placeholder="Last Name *"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              maxLength={50}
            />
            {errors.lastName && (
              <p className=" left-1 md:-bottom-5 md:absolute text-sm italic text-red-500">
                {errors.lastName}
              </p>
            )}
          </div>
        </div>

        {errors.firstName && (
          <p className="lg:block hidden mt-0 text-sm italic text-red-500">
            {errors.firstName}
          </p>
        )}
      </div>
      <div className="mb-4">
        <div className="sm:flex-row sm:space-y-0 sm:space-x-3 flex flex-col space-y-3">
          <div className="sm:w-1/2 w-full">
            <label className="text-grey-darker block mb-2 text-sm font-bold">
              Phone Number:
            </label>
            <input
              className="text-grey-darker focus:shadow-[#000E66] focus:shadow-md focus:outline-none focus:shadow-outline w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
              type="tel"
              name="phoneNumber"
              placeholder="Phone Number *"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneNumber}
              maxLength={13}
            />
            {errors.phoneNumber && (
              <p className="text-sm italic text-red-500">
                {errors.phoneNumber}
              </p>
            )}
          </div>
          <div className="sm:w-1/2 w-full">
            <label className="text-grey-darker block mb-2 text-sm font-bold">
              Email Address:
            </label>
            <input
              className="text-grey-darker focus:shadow-[#000E66] focus:shadow-md focus:outline-none focus:shadow-outline w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
              type="email"
              name="email"
              placeholder="Email Address *"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              maxLength={50}
            />
            {errors.email && (
              <p className="ml-[2px] text-sm italic text-red-500">
                {errors.email}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
