import React from "react";
import ServicesImg from "../../assets/home/Services.png";
import Button from "./Button";
import { Link } from "react-router-dom";
const Service = () => {
  return (
    <>
      <div
        id="SERVICES  "
        className=" lg:flex-row lg:px-32 flex flex-col items-center px-4 mb-6">
        <div className="lg:hidden lg:pr-6 flex justify-center my-4">
          <h3 className="border-secondary l rounded-3xl inline-block p-2 px-5 mt-5 font-serif font-semibold border">
            Services
          </h3>
        </div>
        <div className=" lg:mt-8 flex-1">
          <img
            src={ServicesImg}
            alt="Photo of a nurse with elder man"
            className=" lg:mr-auto w-full"
          />
        </div>
        <div className=" flex-1 p-4">
          <div className="lg:flex justify-start hidden pr-6 my-4">
            <h3 className="border-secondary l rounded-3xl inline-block p-2 px-5 mt-5 font-serif font-semibold border">
              Services
            </h3>
          </div>
          <h3 className="lg:text-3xl text-[#001991] md:text-2xl lg:text-start mb-6 font-serif text-xl text-center">
            Delivering Quality Healthcare with Personalized Staffing Solutions
          </h3>
          <p className="text-secondary text-[18px] flex-1 mb-6 leading-8 text-justify">
            Experience the HPC Medical Staffing difference with our bespoke
            staffing solutions, ensuring quality and flexibility. Our services
            include the recruitment of RNs, LPNs, and LNAs/CNAs, all dedicated
            to providing exceptional patient care.
          </p>
          <div className="lg:justify-start flex justify-center">
            <Link to="/services">
              <Button>View All</Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
