import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CookieNotification = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)showCookieNotification\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (cookieValue !== "false") {
      setTimeout(() => setShow(true), 3000);
    }
  }, []);

  const handleClick = () => {
    document.cookie = "showCookieNotification=false; max-age=31536000";
    setShow(false);
  };
  const handleCancel = () => {
    setShow(false);
  };

  return (
    show && (
      <div className="bottom-10 bg-hover sm:bottom-4 lg:p-4 lg:flex fixed inset-x-0 items-center justify-between p-3 pb-2 mx-4 rounded-lg shadow-lg">
        <div className="sm:mb-2 lg:flex items-center flex-1">
          <span className="lg:flex hidden p-2 bg-green-700 rounded-lg"></span>
          <p className="lg:text-right lg:ml-3 font-medium text-center text-white">
            This site uses cookies for personalization and analytics. You can
            read about our cookie policy{" "}
            <Link to="/cookie-policy" className="hover:underline text-blue-900">
              here.
            </Link>
          </p>
        </div>
        <div className=" lg:gap-8 lg:my-0 flex justify-center gap-4 my-4">
          <button
            onClick={handleClick}
            className="hover:bg-gray-200 flex items-center justify-center px-4 py-2 text-sm font-medium text-indigo-600 bg-white border border-transparent rounded-md shadow-sm">
            Accept
          </button>

          <button
            onClick={handleCancel}
            className="hover:bg-gray-200 flex items-center justify-center px-4 py-2 text-sm font-medium text-indigo-600 bg-white border border-transparent rounded-md shadow-sm">
            Reject
          </button>
        </div>
      </div>
    )
  );
};

export default CookieNotification;
