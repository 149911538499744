import React from "react";

function MiscellaneousForm({ handleChange, values, errors }) {
  return (
    <div className="sm:grid-cols-2 grid grid-cols-1 gap-6">
      <div className="p-4 border rounded">
        <label className="block mb-2 text-sm font-semibold">
          I Have a car available, and will utilize it in the performance of my
          duties:
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            id="car_yes"
            name="carAvailable"
            value="yes"
            onChange={handleChange}
            checked={values.carAvailable === "yes"}
          />
          <label htmlFor="car_yes">Yes</label>
          <input
            type="radio"
            id="car_no"
            name="carAvailable"
            value="no"
            onChange={handleChange}
            checked={values.carAvailable === "no"}
          />
          <label htmlFor="car_no">No</label>
        </div>
        {errors.carAvailable && (
          <p className="mt-1 text-sm italic text-red-500">
            {errors.carAvailable}
          </p>
        )}
      </div>
      <div className="p-4 border rounded">
        <label className="block mb-2 text-sm font-semibold">
          Have a current, VALID driver's license:
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            id="license_yes"
            name="validLicense"
            value="yes"
            onChange={handleChange}
            checked={values.validLicense === "yes"}
          />
          <label htmlFor="license_yes">Yes</label>
          <input
            type="radio"
            id="license_no"
            name="validLicense"
            value="no"
            onChange={handleChange}
            checked={values.validLicense === "no"}
          />
          <label htmlFor="license_no">No</label>
        </div>
        {errors.validLicense && (
          <p className="mt-1 text-sm italic text-red-500">
            {errors.validLicense}
          </p>
        )}
      </div>
      <div className="p-4 border rounded">
        <label className="block mb-2 text-sm font-semibold">
          Have automobile liability insurance $100,000/$300,000 person per
          accident which includes HPC Requirements (Insurance on my vehicle or I
          am willing to purchase insurance):
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            id="insurance_yes"
            name="insuranceRequirement"
            value="yes"
            onChange={handleChange}
            checked={values.insuranceRequirement === "yes"}
          />
          <label htmlFor="insurance_yes">Yes</label>
          <input
            type="radio"
            id="insurance_no"
            name="insuranceRequirement"
            value="no"
            onChange={handleChange}
            checked={values.insuranceRequirement === "no"}
          />
          <label htmlFor="insurance_no">No</label>
        </div>
        {errors.insuranceRequirement && (
          <p className="mt-1 text-sm italic text-red-500">
            {errors.insuranceRequirement}
          </p>
        )}
      </div>
      <div className="p-4 border rounded">
        <label className="block mb-2 text-sm font-semibold">
          I can provide documentation that I am at least eighteen (18) years of
          age:
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            id="age_yes"
            name="ageVerification"
            value="yes"
            onChange={handleChange}
            checked={values.ageVerification === "yes"}
          />
          <label htmlFor="age_yes">Yes</label>
          <input
            type="radio"
            id="age_no"
            name="ageVerification"
            value="no"
            onChange={handleChange}
            checked={values.ageVerification === "no"}
          />
          <label htmlFor="age_no">No</label>
        </div>
        {errors.ageVerification && (
          <p className="mt-1 text-sm italic text-red-500">
            {errors.ageVerification}
          </p>
        )}
      </div>
      <div className="p-4 border rounded">
        <label className="block mb-2 text-sm font-semibold">
          Based on review of the description of the job which you have applied
          are you able to meet and/or perform the qualifications requirements
          and job specifications as defined in the job description:
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            id="description_yes"
            name="jobDescriptionUnderstanding"
            value="yes"
            onChange={handleChange}
            checked={values.jobDescriptionUnderstanding === "yes"}
          />
          <label htmlFor="description_yes">Yes</label>
          <input
            type="radio"
            id="description_no"
            name="jobDescriptionUnderstanding"
            value="no"
            onChange={handleChange}
            checked={values.jobDescriptionUnderstanding === "no"}
          />
          <label htmlFor="description_no">No</label>
        </div>
        {errors.jobDescriptionUnderstanding && (
          <p className="mt-1 text-sm italic text-red-500">
            {errors.jobDescriptionUnderstanding}
          </p>
        )}
      </div>
      <div className="p-4 border rounded">
        <label className="block mb-2 text-sm font-semibold">
          Have you ever pleaded guilty been found guilty of crime civilian or
          military? If yes please provide details:
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            id="criminal_yes"
            name="criminalRecord"
            value="yes"
            onChange={handleChange}
            checked={values.criminalRecord === "yes"}
          />
          <label htmlFor="criminal_yes">Yes</label>
          <input
            type="radio"
            id="criminal_no"
            name="criminalRecord"
            value="no"
            onChange={handleChange}
            checked={values.criminalRecord === "no"}
          />
          <label htmlFor="criminal_no">No</label>
        </div>
        {errors.criminalRecord && (
          <p className="mt-1 text-sm italic text-red-500">
            {errors.criminalRecord}
          </p>
        )}
      </div>
    </div>
  );
}

export default MiscellaneousForm;
