export const CONTACT_FIELDS = {
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  phoneNumber: "Telephone Number",
  email: "Email Id",
  description: "Description",
  date: "Date",
  time: "Time",
};
