import React from "react";
import errorImage from "../assets/Error404.svg";
import Button from "../components/UI/Button";
import { Link } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();
logEvent(analytics, "Error page");
const ErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Link to="/" className="mb-6">
        <Button>Go back to Home page</Button>
      </Link>
      <img src={errorImage} alt="page not found" className="w-[50%]" />
    </div>
  );
};

export default ErrorPage;
