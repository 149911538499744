import React from "react";
import { TIME_SLOTS } from "../../constants/TimeSlot";

const EmployerDetailsForm = ({ handleChange, values, errors }) => {
  const today = new Date();
  today.setDate(today.getDate() + 1); // Add one day
  const minDate = today.toISOString().split("T")[0];
  return (
    <>
      <h3 className="mb-4 text-2xl font-bold text-center">Employer Details</h3>
      <div className="mb-6">
        <label className="text-grey-darker block mb-2 text-sm font-bold">
          Position In The Company:
        </label>
        <input
          className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
          type="text"
          name="positionInCompany"
          placeholder="Enter position in the company *"
          onChange={handleChange}
          value={values.positionInCompany}
          maxLength={50}
        />
        {errors.positionInCompany && (
          <p className="text-sm italic text-red-500">
            {errors.positionInCompany}
          </p>
        )}
      </div>
      <div className="mb-6">
        <label className="text-grey-darker block mb-2 text-sm font-bold">
          What Position(s) In Your Company Would You Like To Fill?
        </label>
        <textarea
          className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
          name="positionsToFill"
          placeholder="Enter positions you would like to fill *"
          onChange={handleChange}
          value={values.positionsToFill}
          maxLength={100}
        />
        {errors.positionsToFill && (
          <p className="text-sm italic text-red-500">
            {errors.positionsToFill}
          </p>
        )}
      </div>
      <div className="mb-6">
        <label className="text-grey-darker block mb-2 text-sm font-bold">
          How Do You Prefer To Be Contacted?
        </label>
        <select
          className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
          name="preferredContactMethod"
          onChange={handleChange}
          value={values.preferredContactMethod}>
          <option value="">Select...</option>
          <option value="phone">Phone</option>
          <option value="email">Email</option>
        </select>
        {errors.preferredContactMethod && (
          <p className="text-sm italic text-red-500">
            {errors.preferredContactMethod}
          </p>
        )}
      </div>
      <div className="md:flex mb-6 -mx-3">
        <div className="md:w-1/2 px-3 mb-6">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Preferred Date:
          </label>
          <input
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            type="date"
            name="preferredDate"
            onChange={handleChange}
            value={values.preferredDate}
            min={minDate}
          />
          {errors.preferredDate && (
            <p className="text-sm italic text-red-500">
              {errors.preferredDate}
            </p>
          )}
        </div>
        <div className="md:w-1/2 px-3">
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Preferred Time Slot:
          </label>
          <select
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
            name="preferredTimeSlot"
            onChange={handleChange}
            value={values.preferredTimeSlot}>
            <option value="">Select...</option>
            {TIME_SLOTS.map((slot) => (
              <option key={slot.value} value={slot.value}>
                {slot.label}
              </option>
            ))}
          </select>
          {errors.preferredTimeSlot && (
            <p className="text-sm italic text-red-500">
              {errors.preferredTimeSlot}
            </p>
          )}
        </div>
      </div>
      <div className="mb-6">
        <label className="text-grey-darker block mb-2 text-sm font-bold">
          Description:
        </label>
        <textarea
          className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full px-3 py-2 leading-tight border rounded shadow appearance-none"
          name="description"
          placeholder="Enter description *"
          onChange={handleChange}
          value={values.description}
          maxLength={500}
        />
        {errors.description && (
          <p className="-mt-1 text-sm italic text-red-500">
            {errors.description}
          </p>
        )}
      </div>
    </>
  );
};

export default EmployerDetailsForm;
