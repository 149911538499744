import { CONTACT } from ".";

export const PRIVACY_POLICY = [
  {
    title: "Information Collection",
    content:
      "HPC may collect personal information, such as your name, contact information, professional credentials, and, if applicable, sensitive information (such as health-related data), when you access our website or interact with our services. We may also collect information through the use of cookies and similar technologies to enhance your browsing experience and analyze website traffic.",
  },
  {
    title: "Use of Information",
    content:
      "HPC uses the information collected to provide and improve our services, communicate with you, facilitate staffing placements, and, if applicable, comply with legal requirements. We may also use cookies and similar technologies to track website usage, personalize content, and deliver targeted advertisements.",
  },
  {
    title: "Disclosure of Information",
    content:
      "HPC may disclose your personal information to third parties, including members of our corporate group, selected third-party service providers, and as required by law or legal process. We take reasonable steps to ensure that these third parties comply with applicable privacy laws and maintain adequate security measures to protect your personal information.",
  },
  {
    title: "Data Security",
    content:
      "HPC employs reasonable technical, administrative, and physical security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet is 100% secure, and HPC cannot guarantee absolute security.",
  },
  {
    title: "Data Storage and Retention",
    content:
      "All information collected by HPC is stored and processed in the United States. We retain information about you for as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, resolve disputes, and enforce our agreements.",
  },
  {
    title: "Your Rights",
    content: ` You have the right to access, correct, or delete your personal information. You may also withdraw your consent to the use of your information or object to certain processing activities. To exercise these rights or to lodge a complaint, please contact us at ${CONTACT.email}.`,
  },
  {
    title: "Marketing Communications",
    content:
      "If you have opted in to receive marketing communications from HPC, you may opt out at any time by following the unsubscribe instructions included in the communication or by contacting us directly.",
  },
  {
    title: "International Data Transfers",
    content:
      "HPC may transfer your personal information to countries outside of your country of residence, including the United States, for processing and storage. By using our website and providing your personal information, you consent to such transfers.",
  },
  {
    title: "Children's Privacy",
    content:
      "HPC does not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided personal information to us, please contact us so that we can delete the information.",
  },
  {
    title: "Changes to this Policy",
    content:
      "HPC reserves the right to update or modify this Privacy Policy at any time without prior notice. Changes will be effective immediately upon posting on our website.",
  },
];
