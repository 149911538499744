import {
  ref,
  set,
  get,
  remove,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { db } from "../firebase";
import { errorLog } from "./firestore";

export const createData = async (data, path, id) => {
  const currentDate = new Date();
  const options = { timeZone: "America/New_York" };
  const date = currentDate.toLocaleDateString("en-US", options);
  const time = currentDate.toLocaleTimeString("en-US", options);

  const newData = {
    id,
    date,
    time,
    ...data,
  };
  try {
    const result = await set(ref(db, path + "/" + id), newData);
    console.log("Data write successful");
    return true;
  } catch (error) {
    console.log("Error writing data:", error);
    errorLog("Form upload Error", newData, error);
    return false;
  }
};

export const deleteData = async (path) => {
  const dataRef = ref(db, path);
  try {
    const result = await remove(dataRef);
    console.log("Data deleted successfully");
    return true;
  } catch (error) {
    errorLog("Form delete Error", null, error, path);
    console.log("Error deleting data:", error);

    return false;
  }
};

export const readData = async (path) => {
  const dataRef = ref(db, path);
  try {
    const snapshot = await get(dataRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available");
      return null;
    }
  } catch (error) {
    errorLog("Form list fetching Error", null, error, path);
    console.error(error);
    return "Error fetching data";
  }
};

export const fetchDataByCategory = async (path, category, value) => {
  try {
    // Create a query to filter data by name
    const dataQuery = query(
      ref(db, path),
      orderByChild(category),
      equalTo(value)
    );

    // Fetch data based on the query
    const snapshot = await get(dataQuery);

    // Process the snapshot to get the data
    if (snapshot.exists()) {
      const data = snapshot.val();
      console.log("Data fetched successfully:", data);
      return data;
    } else {
      console.log(`No data found for ${category}:`, value);
      return null;
    }
  } catch (error) {
    errorLog("Form data fetching Error", null, error, path);
    console.log("Error fetching data:", error);
    return "Error fetching data";
  }
};
