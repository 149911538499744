export const EMPLOYEE_FIELDS = {
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  phoneNumber: "Telephone Number",
  email: "Email Id",
  address: "Address",
  city: "City",
  state: "State",
  pinCode: "Zip Code",
  positionInCompany: "Your position in the Company",
  positionsToFill:
    "What Position(s) in your company would you like to fill? Please also provide The qualifications and/or The number of staffs you need.",
  preferredContactMethod: "How do you prefer to be contacted?",
  preferredDate: "Preferred Date",
  preferredTimeSlot: "Preferred time",
  description: "Description",
};
