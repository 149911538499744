import React from "react";

function PositionForm({ handleChange, values, errors }) {
  return (
    <div className="space-y-4">
      <div className="p-4 border rounded">
        <label className="block mb-2 font-semibold">
          Are you legally entitled to work in U.S.?
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            id="rn_yes"
            name="workEntitle"
            value="Yes"
            onChange={handleChange}
            checked={values.workEntitle === "Yes"}
          />
          <label htmlFor="rn_yes">Yes</label>
          <input
            type="radio"
            id="rn_no"
            name="workEntitle"
            value="No"
            onChange={handleChange}
            checked={values.workEntitle === "No"}
          />
          <label htmlFor="rn_no">No</label>
        </div>
        {errors.workEntitle && (
          <p className="mt-1 text-sm italic text-red-500">
            {errors.workEntitle}
          </p>
        )}
      </div>
      <div className="p-4 border rounded">
        <label className="block mb-2 font-semibold">
          Are you applying for RN?
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            id="rn_yes"
            name="registered_nurse"
            value="Yes"
            onChange={handleChange}
            checked={values.registered_nurse === "Yes"}
          />
          <label htmlFor="rn_yes">Yes</label>
          <input
            type="radio"
            id="rn_no"
            name="registered_nurse"
            value="No"
            onChange={handleChange}
            checked={values.registered_nurse === "No"}
          />
          <label htmlFor="rn_no">No</label>
        </div>
        {errors.registered_nurse && (
          <p className="mt-1 text-sm italic text-red-500">
            {errors.registered_nurse}
          </p>
        )}
      </div>
      <div className="p-4 border rounded">
        <label className="block mb-2 font-semibold">
          Are you applying for LPN?
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            id="lpn_yes"
            name="lp_nurse"
            value="Yes"
            onChange={handleChange}
            checked={values.lp_nurse === "Yes"}
          />
          <label htmlFor="lpn_yes">Yes</label>
          <input
            type="radio"
            id="lpn_no"
            name="lp_nurse"
            value="No"
            onChange={handleChange}
            checked={values.lp_nurse === "No"}
          />
          <label htmlFor="lpn_no">No</label>
        </div>
        {errors.lp_nurse && (
          <p className="mt-1 text-sm italic text-red-500">{errors.lp_nurse}</p>
        )}
      </div>
      <div className="p-4 border rounded">
        <label className="block mb-2 font-semibold">
          Are you applying for LNA/CNA?
        </label>
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            id="lna_cna_yes"
            name="lna_cna"
            value="Yes"
            onChange={handleChange}
            checked={values.lna_cna === "Yes"}
          />
          <label htmlFor="lna_cna_yes">Yes</label>
          <input
            type="radio"
            id="lna_cna_no"
            name="lna_cna"
            value="No"
            onChange={handleChange}
            checked={values.lna_cna === "No"}
          />
          <label htmlFor="lna_cna_no">No</label>
        </div>
        {errors.lna_cna && (
          <p className="mt-1 text-sm italic text-red-500">{errors.lna_cna}</p>
        )}
      </div>
    </div>
  );
}

export default PositionForm;
