import React, { useState, useEffect } from "react";
import {
  Link,
  useParams,
  useLocation,
  useNavigate,
  NavLink,
} from "react-router-dom";
import { deleteData, fetchDataByCategory } from "../firebase/database";
import FormPaper from "../components/FormPaper";
import LoadingModal from "../components/modal/LoadingModal";
import { CONTACT_FIELDS } from "../constants/ContactFormField";
import { EMPLOYMENT_FIELDS } from "../constants/EmploymentFormFIelds";
import { EMPLOYEE_FIELDS } from "../constants/EmployeeFormFields";
import toast from "react-hot-toast";
import DeleteModal from "../components/modal/DeleteModal";
import ScrollToTop from "../components/ScrollToTop";
import logo from "../assets/hpc logo.jpg";
import { deleteResume } from "../firebase/storage";
const ApplicationData = () => {
  const [data, setData] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const segments = pathname.split("/");

  segments.pop(); // remove the last segment
  const newPathname = segments.join("/");

  let path, fields;

  if (newPathname === "/contact-application") {
    path = "Contact-List/";
    fields = CONTACT_FIELDS;
  } else if (newPathname === "/employment-application") {
    path = "Employment-List/";
    fields = EMPLOYMENT_FIELDS;
  } else if (newPathname === "/employer-application") {
    path = "Employee-List/";
    fields = EMPLOYEE_FIELDS;
  }

  useEffect(() => {
    if (id) {
      handleFetchData();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFetchData = async () => {
    const result = await fetchDataByCategory(path, "id", id);
    const item = result[id];

    setData(item);
    console.log(item);
  };
  const handleDelete = async () => {
    if (newPathname === "/Employment-Application") {
      const SResult = await deleteResume(data.resumeUrl);
      console.log("resume deleted", SResult);
    }

    const filePath = path + id;
    const result = await deleteData(filePath);
    if (result) {
      setIsDeleteModal(false);
      navigate(-1);
    } else {
      toast.error("Error deleting application");
      setIsDeleteModal(false);
    }
  };

  return (
    <>
      <ScrollToTop />
      {isDeleteModal && (
        <DeleteModal
          onCancel={() => {
            setIsDeleteModal(false);
          }}
          onDelete={handleDelete}
        />
      )}
      {Object.keys(data).length === 0 && <LoadingModal />}
      {Object.keys(data).length !== 0 && (
        <div className="container px-4 py-8 mx-auto">
          <div className=" flex justify-between">
            <Link
              to={-1}
              className="hover:text-hover inline-flex items-center my-4 space-x-1 text-sm font-medium">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
              <span>Back</span>
            </Link>
            <NavLink to="/" className=" flex items-center gap-2">
              <img src={logo} alt="" className=" size-12" />
              <p className=" lg:block hidden font-serif font-semibold">
                HPC Medical Staffing
              </p>
            </NavLink>
            <button
              className="hover:bg-[#001891e7]  my-1 px-2  lg:px-4 text-sm font-medium text-white bg-[#001991] border border-transparent rounded-xl  shadow-sm"
              onClick={() => {
                setIsDeleteModal(true);
              }}>
              Delete
            </button>
          </div>
          <h1 className="mb-4 text-3xl font-bold">Application Details</h1>
          <div className="lg:flex items-center justify-between px-4 py-2 mb-4 bg-gray-100 rounded shadow-md">
            <span className="lg:inline lg:text-lg block">{`Name: ${
              data.firstName
            } ${data.middleName ? data.middleName : ""} ${
              data.lastName
            } `}</span>
            <span className="lg:text-lg">
              Uploaded on {data.date} at {data.time}
            </span>
          </div>
          <FormPaper data={data} labels={fields} />
          {newPathname === "/employment-application" && (
            <div>
              <h1 className="my-4 text-3xl font-bold text-center">Resume</h1>
              <iframe className="w-full h-screen mt-4" src={data?.resumeUrl} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ApplicationData;
