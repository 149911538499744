import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase/index";
import Button from "../components/UI/Button";
import toast, { Toaster } from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa6";
import logo from "../assets/hpc logo.jpg";
import bg from "../assets/bg/service.svg";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setPersistence(auth, browserLocalPersistence);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/admin");
      }
    });
    return unsubscribe;
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoggingIn(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoggingIn(false);
      navigate("/admin");
      console.log("Login Successful");
    } catch (error) {
      console.error("Error logging in in:", error);
      setLoggingIn(false);
      switch (error.code) {
        case "auth/invalid-email":
          toast.error("Invalid email format.");
          break;
        case "auth/user-disabled":
          toast.error("This user has been disabled.");
          break;
        case "auth/user-not-found":
          toast.error("User not found.");
          break;
        case "auth/wrong-password":
          toast.error("Incorrect password.");
          break;
        case "auth/invalid-password":
          toast.error("Incorrect password.");
          break;
        case "auth/invalid-credential":
          toast.error("Incorrect Email/Password.");
          break;
        default:
          toast.error(error.message);
      }
    }
  };

  return (
    <div
      className="h-screen"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}>
      <Toaster />
      <div className=" lg:px-4 flex justify-center py-8">
        <Link
          to="/"
          className="text-accent hover:text-hover lg:items-center inline-flex mb-4 mr-auto space-x-1 text-sm font-medium">
          <FaArrowLeft className="size-4" />
          <span className="font-medium">Back</span>
        </Link>
        <NavLink
          to="/"
          className=" flex flex-col items-center mr-auto space-y-2">
          <img src={logo} alt="" className="size-12" />
          <p className=" lg:block hidden font-serif font-semibold">
            HPC Medical Staffing
          </p>
        </NavLink>
        <span className="w-14"></span>
      </div>
      <div className=" flex items-center justify-center">
        <form
          onSubmit={handleLogin}
          className="w-full max-w-md px-5 py-8 bg-white rounded shadow-md">
          <h2 className="mb-6 text-2xl font-bold text-center text-gray-700">
            Welcome, Staff
          </h2>
          <label htmlFor="email" className="ml-1">
            Email
          </label>
          <input
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 mb-3 border rounded focus:outline-none focus:shadow-[#000E66] focus:shadow-md"
          />
          <label htmlFor="password" className="ml-1">
            Password
          </label>
          <input
            id="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-3 py-2 mb-5 border rounded focus:outline-none focus:shadow-[#000E66] focus:shadow-md"
          />
          <Button
            type="submit"
            disabled={loggingIn}
            className="w-full px-3 py-2 text-white hover:bg-blue-600 focus:outline-none focus:shadow-outline lg:text-[18px] focus:shadow-[#000E66] focus:shadow-md">
            {loggingIn ? "Logging In" : "LOGIN"}
          </Button>
          <div className="flex items-center justify-between mt-6"></div>
        </form>
      </div>
    </div>
  );
};

export default Login;
