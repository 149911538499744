import React, { forwardRef, useEffect } from "react";
import { TESTIMONIALS } from "../../constants/Testimonials";
import Card from "./Card";
import { useLocation } from "react-router-dom";

const Testimonials = forwardRef((props, ref) => {
  const { pathname } = useLocation();
  const segments = pathname.split("/");

  segments.pop(); // remove the last segment
  const newPathname = segments.join("/");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="testimonials" ref={ref} className="lg:px-32 px-4 pb-6 my-8">
      <h3 className="font-serif text-4xl font-semibold text-center text-gray-800">
        Voices of Trust
      </h3>
      <h4 className="text-secondary mt-4 text-lg font-medium text-center">
        Discover the Stories Our Clients Share
      </h4>
      <div className="lg:grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 pt-4">
        {TESTIMONIALS.map((item) => (
          <Card
            key={item.name}
            name={item.name}
            description={item.description}
            role={item.role}
          />
        ))}
      </div>
    </div>
  );
});

export default Testimonials;
