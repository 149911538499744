import React, { useEffect } from "react";
import Hero from "../assets/about/Hero.jpg";
import Emphasis from "../assets/about/Emphasis.jpg";
import GratitudeImage from "../assets/about/Gratitude.jpg";
import dedicatedTeam from "../assets/about/Dedicated.jpg";
import Button from "../components/UI/Button";
import { Link } from "react-router-dom";
import bg from "../assets/bg/service.svg";
import bg1 from "../assets/bg/serviceReverse.svg";
import ScrollToTop from "../components/ScrollToTop";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();
logEvent(analytics, "About page");

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ScrollToTop />
      <div
        className="lg:py-6 lg:px-32 px-8 py-2"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}>
        <div className=" lg:justify-between bg-opacity-30 justify-center">
          <h1 className="lg:text-4xl pb-6 mt-2 font-serif text-3xl font-medium text-center text-gray-800">
            About HPC Medical Staffing LLC
          </h1>
          <div className="lg:flex-row lg:py-8 flex flex-col items-center py-2">
            <div className="lg:w-1/2">
              <p className="lg:mb-6 text-lg text-justify text-gray-700">
                At HPC Medical Staffing LLC, we're dedicated to delivering
                exceptional medical staffing solutions that put your healthcare
                satisfaction at the forefront. Founded by partners with a shared
                vision, we've grown from a small recruitment agency in New
                Hampshire, USA, to a leading healthcare staffing agency serving
                all major cities in the state.
              </p>
              <p className="lg:mb-6 text-lg text-justify text-gray-700">
                Our commitment goes beyond traditional staffing models. We offer
                a comprehensive range of services, including travel, per diem,
                and block booking contracts, as well as part-time pick-up
                placement recruitment. Whether you need RNs, LPNs, LNAs/CNAs, or
                other medical professionals, we specialize in matching skilled
                individuals with the unique needs of nursing homes and
                healthcare facilities.
              </p>
              <p className="t text-lg text-justify text-gray-700">
                At HPC Medical Staffing, we believe that where compassion meets
                excellence, exceptional care is born. As your partners in
                health, we're committed to ensuring quality care for all. Our
                focus on quality, reliability, and personalized service drives
                us to be the premier choice for medical staffing solutions in
                New Hampshire and beyond.
              </p>
            </div>
            <div className="lg:w-1/2 flex">
              <img
                src={Hero}
                alt="Group of nurses standing one after other"
                className="  items-center rounded-xl mx-auto lg:mx-0 mt-4 lg:mt-0  lg:ml-auto w-[90%]"
              />
            </div>
          </div>
        </div>
        <div className=" flex justify-center py-4">
          <h3 className="border-secondary l rounded-3xl inline-block p-2 px-5 mt-5 font-serif font-semibold border">
            What Sets Us Apart
          </h3>
        </div>

        <div className="overflow-hidden mx-6 lg:mx-[60px]  text-center bg-white rounded-lg shadow-lg">
          <div className="p-6">
            <h3 className="mb-4 text-xl font-bold"> Mission-Driven Approach</h3>
            <p className=" text-gray-700">
              Our core mission is to address staffing shortages while upholding
              the highest standards of healthcare delivery. Every decision we
              make and every action we take is guided by this mission, ensuring
              that we contribute meaningfully to the healthcare ecosystem in New
              Hampshire.
            </p>
          </div>
        </div>
      </div>
      <div
        className="md:grid-cols-2 lg:grid-cols-3 lg:px-32 grid grid-cols-1 gap-8 px-4 mb-8"
        style={{
          backgroundImage: `url(${bg1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}>
        {/* Dedicated Team*/}
        <div className="relative flex flex-col overflow-hidden bg-white rounded-lg shadow-lg">
          <img
            src={dedicatedTeam}
            alt="Group of nurses working "
            className="object-cover w-full"
          />
          <div className="flex-grow p-6">
            <h3 className="lg:text-start mb-4 text-xl font-bold text-center">
              Dedicated Team
            </h3>
            <p className="text-justify text-gray-700">
              Our team is composed of passionate and dedicated individuals who
              work tirelessly to connect healthcare facilities with the most
              qualified professionals. From recruitment to placement, our team
              members go above and beyond to ensure that both healthcare
              facilities and professionals find the perfect match.
            </p>
          </div>
          <div className="bottom flex my-4 text-base">
            <Link to="/career" className="mx-auto">
              <Button> Join Us</Button>
            </Link>
          </div>
        </div>

        {/* Emphasis on Human Connection */}
        <div className="relative flex flex-col overflow-hidden bg-white rounded-lg shadow-lg">
          <img
            src={Emphasis}
            alt="Photo of nurse with a patient"
            className="object-cover w-full"
          />
          <div className="flex-grow p-6">
            <h3 className="lg:text-start mb-4 text-xl font-bold text-center">
              Emphasis on Human Connection
            </h3>
            <p className="text-justify text-gray-700">
              HPC Medical Staffing LLC firmly believes in the power of human
              connection and the transformative impact that each healthcare
              professional can make in the lives of patients. By fostering
              strong relationships between facilities and Healthcare
              professionals, we create an environment where compassionate care
              thrives, and patients receive the quality treatment they deserve.
            </p>
          </div>
          <div className="flex my-4">
            <Link to="/career" className="mx-auto">
              <Button> Join Us</Button>
            </Link>
          </div>
        </div>

        {/* Gratitude and Trust*/}
        <div className="relative flex flex-col overflow-hidden bg-white rounded-lg shadow-lg">
          <img
            src={GratitudeImage}
            alt="LNA/CNA"
            className="object-cover w-full"
          />
          <div className="flex-grow p-6">
            <h3 className="lg:text-start mb-4 text-xl font-bold text-center">
              Gratitude and Trust
            </h3>
            <p className="text-justify text-gray-700">
              HPC Medical Staffing LLC is deeply grateful for the trust placed
              in us by healthcare facilities and professionals alike. As your
              healthcare staffing partner, we understand the importance of this
              trust and are committed to exceeding your expectations every step
              of the way. Your success is our success, and we look forward to
              continuing to serve you with dedication and integrity.
            </p>
          </div>
          <div className="lg:my-4 flex">
            <Link to="/career" className=" mx-auto">
              <Button> Join Us</Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
