export const EMPLOYMENT_INITIAL_STATE = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
  pinCode: "",
  email: "",
  workEntitle: "",
  registered_nurse: "",
  lp_nurse: "",
  lna_cna: "",
  employer: "",
  employer_number: "",
  employer_address: "",
  working_from: "",
  worked_until: "",
  schoolName: "",
  degree: "",
  jobTitle: "",
  working_hoursPerWeek: "",
  specificDuties: "",
  lastSalary: "",
  reasonForLeaving: "",
  contactEmployer: "",
  referenceName: "",
  authorization: "",
  referenceRelationship: "",
  referenceNumber: "",
  referenceAddress: "",

  carAvailable: "",
  validLicense: "",
  insuranceRequirement: "",
  ageVerification: "",
  jobDescriptionUnderstanding: "",
  criminalRecord: "",
};

export const EMPLOYER_INITIAL_STATE = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
  pinCode: "",
  email: "",
  positionInCompany: "",
  positionsToFill: "",
  preferredContactMethod: "",
  preferredDate: "",
  preferredTimeSlot: "",
  description: "",
};

export const CONTACT_INITIAL_STATE = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  description: "",
};
